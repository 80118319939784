import { CustomerApiService } from '@/api/index';
import ConfirmModal from '@/components/ToolBar/ConfirmModal';
import LabeledValue from '@/components/ToolBar/LabeledValue';

import { setFavouriteSite, setSelectedCustomer, useCurrentUserRole, useFavouriteSite, useSelectedCustomer } from '@/store/customerManagement';
import { UserRole } from '@/types/UserRole';
import TextIconButton from '@/components/ToolBar/TextIconButton';
import { setNotification } from '@/store/submitNotification';
import { getStyling, labelTheme, tableTopButtonTheme, tableTopIconTheme } from '@/styles/theme/themes';
import { queryParamsType } from '@/types/global';
import { Box, Button, Card, Checkbox, Divider, FlexGrid, Icon, IconButton, Spacer, StackView, StackWrap, Tooltip, Typography } from '@telus-uds/components-web';
import { Visible, Invisible } from '@telus-uds/palette-allium/build/web/icons';

import { Delete, Edit } from '@telus-uds/palette-allium/build/web/icons';
import { set } from 'date-fns';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { isAnyAdminRole, isTelusAdmin } from '@/types/RoleMapping';
import { useDispatch } from 'react-redux';
import { emptyValues } from './Site';
import { handleApiError } from '@/utils/error-handler';
import { formatTimeInterval } from '@/components/ToolBar/formatTimeInterval';
import { StyledLabelValueWrapper } from '@/styles/theme/styledComponents';


interface SummaryCardProps {
  type?: string
  index?: string
  name: string
  data: any
  length?: number
  editable?: boolean
  children?: any
  hiddenData?: SiteHiddenData
  preview?: boolean
  favouriteAble?: boolean
}

interface SiteHiddenData {
  id: number
  name: string
}
interface dataToMapProps {
  details: any
  type: string
  hiddenData?: SiteHiddenData
}

const ToggleableLabeledValue = ({ label, value }: { label: string, value: string }) => {
  const [visible, setVisible] = useState(false);
  return (
    <FlexGrid outsideGutter={false} limitWidth={true}>
      <FlexGrid.Row >
        <FlexGrid.Col xs ={12}>     
          {/* <LabeledValue label={label} value={visible ? value : '••••••••'} /> */}
          <StyledLabelValueWrapper>
            <Typography tokens={getStyling(labelTheme)}>{label}</Typography>
            <Typography variant={{ colour: 'light' }}>
              {
                <>
                  {(visible ? value : "•".repeat(value.length)) + "  "}
                  <IconButton onPress={() => setVisible(!visible)} icon={visible ? Invisible : Visible } />
                </>
              }
            </Typography>
          </StyledLabelValueWrapper>  
        </FlexGrid.Col>             
      </FlexGrid.Row>
    </FlexGrid> 
  );
};


const SiteActionButtons = ({ siteName, siteId }: { siteName: string, siteId: number }) => {
  const router = useRouter();
  const currentUserRole = useCurrentUserRole();
  const dispatch = useDispatch();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [alert, setAlert] = useState('');
  const selectedCustomerData = useSelectedCustomer();
  const { id: customerId } = router.query as unknown as queryParamsType;

  const redirectToEditSitePage = async () => {
    await router.push(`${customerId}/edit/sites/${siteId}`);
  };

  const deleteAction = async () => {
    setDeleteModalOpen(false);
    CustomerApiService.deleteSite(customerId, siteId)
      .then((response) => {
        const updatedCustomer = { ...selectedCustomerData };
        const newSites = updatedCustomer.sites.filter((site) => site.id !== siteId);
        dispatch(setSelectedCustomer({ ...updatedCustomer, sites: newSites }));
        dispatch(setNotification({ message: 'Site deleted successfully', type: 'success' }));
      })
      .catch((err: any) => {
        handleApiError(err, dispatch);
      });
  };

  return (
    <>
      <div role="alert" aria-live="assertive" aria-atomic="true" id="alert-message" style={{ position: 'absolute', left: '-9999px' }}>
        <span>{alert}</span>
      </div>
      <StackWrap tokens={{ justifyContent: 'flex-end' }}>
        <StackView space={2} direction="row">
          {isTelusAdmin(currentUserRole) && (
            <>
              <TextIconButton
                content="Edit"
                onClick={redirectToEditSitePage}
                theme={tableTopButtonTheme}
                iconTheme={tableTopIconTheme}
                icon={Edit}
              />
              <Divider variant={{ decorative: true }} vertical={true} />
              <TextIconButton
                content="Delete"
                loaderRequired={false}
                onClick={() => setDeleteModalOpen(true)}
                theme={tableTopButtonTheme}
                iconTheme={tableTopIconTheme}
                icon={Delete}
              />
            </>
          )}
        </StackView>
      </StackWrap>
      {isDeleteModalOpen && (
        <ConfirmModal
          isOpen={isDeleteModalOpen}
          setIsOpen={setDeleteModalOpen}
          heading="Delete Site"
          bodyText={`Are you sure you want to delete site: ${siteName}`}
          confirmButtonText="Confirm"
          onConfirm={async () => {
            setAlert('Confirm selected, returning to original page');
            deleteAction();
          }}
        />
      )}
    </>
  );
};


export const SummaryCard = ({ name, data, length, editable, type, children, hiddenData, preview, favouriteAble }: SummaryCardProps) => {
  const favouriteSite = useFavouriteSite();
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (type === 'sites') setChecked(favouriteSite?.siteId === hiddenData.id);
  }, [favouriteSite, hiddenData]);  
  
  if (type === 'sites' && data[1].value === '') {
      return null
  }
  const currentUserRole = useCurrentUserRole();

  // if you want to adjust the col spacing just for the sites and keep other cards to have the col spacing same as a row with multple cards then give  1st flex.col xl={3.5}, and xl={6} if type is sites
  return (
    <Card name={name} variant={{ background: 'alternative' }} tokens={{ flex: 1 }}>
      <StackView space={2}>
        <StackWrap tokens={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant={{ colour: 'brand3', bold: true }}>{name}</Typography>
          {isAnyAdminRole(currentUserRole?.name) && editable && <SiteActionButtons siteName={hiddenData.name} siteId={hiddenData.id}/>}
        </StackWrap>
        <Divider />
        { !!data &&
        <FlexGrid outsideGutter={false} limitWidth={true}>
          {data.map((item: any, index: number) => {
            const nextIndex = index + 1;
            const row = index % 2 === 0;
            if (row) {
              return (
                <FlexGrid.Row key={index}>
                  <FlexGrid.Col xs={12} xl={6}>
                    {(item.label === 'API Password' || item.label === 'Keystore Password') ? (
                      <ToggleableLabeledValue label={item.label} value={item.value} />
                    ) : (
                      <LabeledValue label={item.label} value={item.value} active={item.active}/>
                    )}
                  </FlexGrid.Col>
                    <FlexGrid.Col xs={12} xl={6}>
                      {data[nextIndex] && (data[nextIndex].label === 'API Password' || data[nextIndex].label === 'Keystore Password') ? (
                        <ToggleableLabeledValue label={data[nextIndex].label} value={data[nextIndex].value} />
                      ) : (
                        <LabeledValue label={data[nextIndex]?.label} value={data[nextIndex]?.value} active={data[nextIndex]?.active}/>
                      )}
                    </FlexGrid.Col>
                </FlexGrid.Row>)
            } else {
              return undefined
            }
          })}
        </FlexGrid>
      }
      {children}
      {type === 'sites' && !preview && favouriteAble && (
      <>
      <Spacer space={5} />
        <StackView direction="row" space={2}>
          <Checkbox 
            label="Favourite this site" 
            checked={checked} 
            onChange={() => {
              if (checked) {
                dispatch(setFavouriteSite({ siteId: null, siteName: null }))
              } else {
                dispatch(setFavouriteSite({ siteId: hiddenData.id, siteName: hiddenData.name }))
              }
              setChecked(!checked)
            }} 
          />
          <Tooltip content="Mark this site as favourite and it will appear as default site when navigating the application. Only one site can be favourited at a time" />
        </StackView>
      </>
    )}
      </StackView>
    </Card>
  )
}

const Summary = ({ data, pageType, pwnSol, preview }: any) => {
  const addressType = ['billing', 'shipping']
  const CustomerDetails = [
    { label: "Customer Name", value: data?.customerName },
    { label: "Currency", value: data?.currency },
    ...(pageType === 'profile'
      ? [{ label: "Customer Id", value: data?.customerId }]
      : []),
    { label: "Language", value: data?.language },
    { label: "BAN", value: data?.ban },
    { label: "Status", value: data?.customerStatus, active: data?.customerStatus.toUpperCase() === 'ACTIVE'}
  ]
  const contactDetails = data?.contacts?.map((item: any, index: number) => (
    [
      { label: "Name", value: item?.contactName },
      { label: "Email", value: item?.emailId },
      { label: "Phone", value: item?.phone },
      { label: "Description", value: item?.description }
    ]
  ))
  const addressDetails = addressType.map((type: string, index: number) => {
    const item = data[type];
    return [
      { label: "Address", value: item?.address },
      { label: "Country", value: item?.country },
      { label: "Province", value: item?.province },
      { label: "City", value: item?.city },
      { label: "Postal Code", value: item?.postalCode },
      ...(type === 'billing' ? [{ label: "Billable", value: item?.billable ? 'Yes' : 'No' }] : [{ label: " ", value: " " }])
    ];
  })


  const siteDetails = data?.sites?.map((item: any, index: number) => {
    const endpoints: any = [];
    // Iterate over HSS Endpoints
    // if (item.hssEndpoints && item.hssEndpoints.length > 0) {
    //   item.hssEndpoints.forEach((endpoint: any, index: any) => {
    //     endpoints.push({ label: index === 0 ? 'HSS Primary' : 'HSS Secondary', value: endpoint });
    //   });
    // }

    // Iterate over MME Endpoints
    if (item.mmeEndpoints && item.mmeEndpoints.length > 0) {
      item.mmeEndpoints.forEach((endpoint: any, index: any) => {
        endpoints.push({ label: `MME Endpoint ${index + 1}`, value: endpoint });
      });
    }


    // Iterate over PGW Endpoints
    if (item.pgwEndpoints && item.pgwEndpoints.length > 0) {
      item.pgwEndpoints.forEach((endpoint: any, index: any) => {
        endpoints.push({ label: `PGW Endpoint ${index + 1}`, value: endpoint });
      });
    }
    return [
      // { label: "Name", value: item?.siteName },
      { label: "PWN Solution", value: (pageType !== 'profile') ? pwnSol[0].name : item?.provider?.name },
      { label: "Address", value: item?.address },
      ...(pageType === 'profile'
      ? []
      : [
            // { label: "Base URL", value: item?.baseUrl },
            { label: "API Username", value: item?.pwnApiUsername },
            { label: "API Password", value: item?.pwnApiPassword },
            { label: "Keystore Password", value: item?.pwnKeystorePassword }
          ]
      ),
      { label: "HSS Primary", value: item?.hssEndpoints[0]},
      ...endpoints,
      { label: "Scheduler Enabled", value: item?.enableScheduler ? "Yes" : "No"},
      ...(item?.enableScheduler ? [{ label: "Scheduler Interval", value: `${formatTimeInterval(item?.schedulerInterval)}`}] : []),
    ];
  })

  const sitesHiddenData = data?.sites?.map((item: any, index: number) => {
    return {
      id: item?.id,
      name: item?.siteName
    }
  })
  const dataToMap: dataToMapProps[] = [
    { details: contactDetails, type: 'contacts' },
    { details: addressDetails, type: 'address' },
    ...(preview ? [{ details: siteDetails, type: 'sites', hiddenData: sitesHiddenData }] : [])
  ];

  const allValuesEmpty = (items: Array<{ label: string, value: string }>): boolean => {
    return items.filter((item) => item.label !== "PWN Solution").every(item => item.value === '' || item.value === undefined);
  }

  const determineName = (type: string, index: number, name?: string) => {
    switch (type) {
      case 'address':
        return index === 0 ? 'BILLING ADDRESS' : 'SHIPPING ADDRESS';
      case 'contacts':
        return index === 0 ? 'PRIMARY CONTACT' : 'ADDITIONAL CONTACT';
      case 'sites':
        return `SITE: ${name}`;
      default:
        return '';
    }
  };
  const contactsObject = dataToMap.find((obj) => obj.type === "contacts");
  const numberContacts = contactsObject ? contactsObject.details.length : 0 // handle edge case where no contact information
  const oddContacts = !(numberContacts % 2 === 0)
  return (
    <StackView>
      <SummaryCard name={'CUSTOMER DETAILS'} data={CustomerDetails} length={1} preview={preview}/>
      {dataToMap.map((dataItem: any, outerIndex: number) => (
        <FlexGrid gutter={false} limitWidth={false} key={outerIndex}>
          <FlexGrid.Row key={outerIndex}>
            {dataItem.details?.map((item: any, innerIndex: number) => (
              <FlexGrid.Col flex={true} xs={12} xl={(( innerIndex === dataItem.details.length-1 && dataItem.details.length % 2 !== 0) || dataItem.type==='sites') ? 12 : 6} key={innerIndex}>
                {(!allValuesEmpty(item) &&
                  <Box flex={1} top= {{md: 2}} right={{ md: innerIndex !== dataItem.details.length - 1 && innerIndex % 2 === 0 && dataItem.type !== 'sites' ? 2 : 0 }}>
                    <SummaryCard
                      name={dataItem.type === 'sites'
                        ? determineName(dataItem.type, innerIndex, dataItem.hiddenData[innerIndex].name)
                        : determineName(dataItem.type, innerIndex)}
                      data={item}
                      length={dataItem.details.length}
                      {...(dataItem.type === 'sites' && { hiddenData: dataItem.hiddenData[innerIndex] })}
                      {...(dataItem.type === 'sites' &&
                      pageType === 'profile' &&
                      { editable: true }
                      )}
                      type={dataItem.type}
                      preview={preview}
                    />
                  </Box>
                )}
              </FlexGrid.Col>
            ))}
          </FlexGrid.Row>
        </FlexGrid>
      ))}
    </StackView>
  )
}

export default Summary
