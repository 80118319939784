import { useCurrentPageable } from '@/store/customerManagement';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';

type SelectedRows = Record<string, boolean>;
type HandleRowSelectionChange = (id: string) => void;

interface SelectedRowsContextType {
  selectedRows: SelectedRows;
  handleRowSelectionChange: HandleRowSelectionChange;
  isAllSelected: boolean;
  onSelectAll: () => void;
  clearSelectedRows: () => void;
}

type SelectedRowsProviderProps<T> = {
  // selectedRows: SelectedRows;
  rowKey: keyof T;
  data: Array<T>
  setSelectedRows: React.Dispatch<React.SetStateAction<SelectedRows>>;
  children: React.ReactNode;
}

// Create a context for managing selected rows
const SelectedRowsContext = createContext<SelectedRowsContextType>({
  selectedRows: {},
  handleRowSelectionChange: () => {},
  isAllSelected: false,
  onSelectAll: () => {},
  clearSelectedRows: () => {},
});

export const SelectedRowsProvider= <T extends {id?: string},>({ setSelectedRows, data, children, rowKey }: SelectedRowsProviderProps<T>) => {
  const [selectedRows, _setSelectedRows] = useState<SelectedRows>({});
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
  const {page: currentPage} = useCurrentPageable()
  // Function to handle row selection change
  const handleRowSelectionChange: HandleRowSelectionChange = (id) => {
    _setSelectedRows((prevSelectedRows) => ({
      ...prevSelectedRows,
      [id]: !prevSelectedRows[id],
    }));
  };

  // Function to select all rows
  const onSelectAll = () => {
    if (!data) return;
    const updatedSelectedRows: SelectedRows = {};
    const keyId = rowKey || 'id';
    for (const key in data) {
      const item = data[key];
      updatedSelectedRows[item[keyId] as string] = !isAllSelected;
    }
    _setSelectedRows(updatedSelectedRows);
    setIsAllSelected(!isAllSelected);
  };

  // removing the below because we want to uncheck the boxes after data submission. This was causing infinite loop when a row was deleted
  
  // check  if all rows after data change are selected, if not the uncheck the select all checkbox
  // useEffect(() => {
  //   const keyId = rowKey || 'id';
  //   const dataKeys = data?.map(item => item[keyId]?.toString());
  //   const selectedRowsKeys = Object.keys(selectedRows);
  //   const areAllSelected = dataKeys?.every(key => selectedRowsKeys.includes(key) && selectedRows[key]);

  //   // delete the selected row key if it is not in the data
  //   const updatedSelectedRows = selectedRowsKeys.reduce((result: {[key: string]: boolean}, key) => {
  //     result[key] = dataKeys?.includes(key) ? selectedRows[key] : false;
  //     return result;
  //   }, {});
  //   // Only update selectedRows if it has actually changed, otherwise it will cause an infinite loop
  //   if (JSON.stringify(updatedSelectedRows) !== JSON.stringify(selectedRows)) {
  //     setSelectedRows(updatedSelectedRows);
  //   }

  //   setIsAllSelected(areAllSelected);
  // }, [data]);



  useEffect(() => {
    if (!setSelectedRows) return;
    setSelectedRows(selectedRows);
  }, [selectedRows]);

  // resets selected rows when the page changes
  useMemo(() => {
    _setSelectedRows({});
  }, [currentPage]);

  // Clears selected rows on command
  const clearSelectedRows = () => {
    _setSelectedRows({});
    setIsAllSelected(false);
  };

  return (
    <SelectedRowsContext.Provider value={{ selectedRows, handleRowSelectionChange, onSelectAll, isAllSelected, clearSelectedRows  }}>
      {children}
    </SelectedRowsContext.Provider>
  );
};

// Custom hook to access selected rows state and selection change handler
export const useSelectedRows = () => {
  return useContext(SelectedRowsContext);
};
