import { SiteRequest } from '@/api/index';
import { hasCustomerRole } from '@/types/RoleMapping';

export const composeValidators =
  (...validators: any[]) =>
    (value: any) =>
      validators.reduce(
        (error, validator) => error || validator(value),
        undefined
      );

const CUSTOMER_NAME_PATTERN = /^[a-zA-Z0-9 '\-\.éàèùâêîôûëïüçÉÀÈÙÂÊÎÔÛËÏÜÇ]+$/;
const USER_NAME_PATTERN = /^[a-zA-Z0-9 '\-\.éàèùâêîôûëïüçÉÀÈÙÂÊÎÔÛËÏÜÇ]+$/;
const TELUS_USER_NAME_PATTERN = /^[xXtT]\d{1,10}$/;
const CONTACT_NAME_PATTERN = /^[a-zA-Z0-9 '\-\.éàèùâêîôûëïüçÉÀÈÙÂÊÎÔÛËÏÜÇ]+$/;
const SITE_NAME_PATTERN = /^[a-zA-Z0-9 ]+$/;
// const POSTAL_CODE_PATTERN = /^(?i)[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z] ?\d[ABCEGHJ-NPRSTV-Z]\d$/;
const POSTAL_CODE_PATTERN = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z] ?\d[ABCEGHJ-NPRSTV-Z]\d$/i;
const SITE_URL_PATTERN = /^(https?:\/\/).*/;
const EMAIL_PATTERN = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const PHONE_PATTERN = /^\d{10}$/;
const USERNAME_PATTERN = /^[a-zA-Z][a-zA-Z0-9._-]{1,253}[a-zA-Z0-9]$/

const IMSI_PATTERN = /^\d{14,15}$/;
const MSISDN_PATTERN = /^\d{10,15}$/;
const ICCID_PATTERN = /^\d{19,20}$/;
const PUK_PATTERN = /^\d{8}$/;
const PIN_PATTERN = /^\d{4,8}$/;
const ENC_OPC_PATTERN = /^[0-9A-Fa-f]{32}$/;
const ENC_KI_PATTERN = /^[0-9A-Fa-f]{32}$/;

export const usernameFormat = (value: string) => {
  return !USERNAME_PATTERN.test(value) ? "Invalid Format" : undefined;
}

export const telusUsernameFormat = (value: string) => {
  return !TELUS_USER_NAME_PATTERN.test(value) ? "Invalid Format" : undefined;
}

export const required = (value: any) => (value ? undefined || value.toString().trim() === '' : "Required");

export const mustBeNumber = (value: number) =>
  value && isNaN(value) ? "Must be a number" : undefined;

export const minValue = (min: number) => (value: number) =>
  isNaN(value) || value >= min
    ? undefined
    : `Should be greater than or equal to ${min}`;

export const maxValue = (max: number) => (value: number) =>
  isNaN(value) || value <= max
    ? undefined
    : `Should be less than or equal to ${max}`;

export const email = (value: string) => {
  return !EMAIL_PATTERN.test(value) ? "Invalid email format" : undefined;
}

export const postalCode = (value: string) => {
  return !POSTAL_CODE_PATTERN.test(value) ? "Invalid Format" : undefined;
}

export const customerNameFormat = (value: string) => {
  return !CUSTOMER_NAME_PATTERN.test(value) ? "Invalid Format" : undefined
}

export const customerUserNameFormat = (value: string) => {
  return !USER_NAME_PATTERN.test(value) ? "Invalid Format" : undefined
}

export const contactNameFormat = (value: string) => {
  return !CONTACT_NAME_PATTERN.test(value) ? "Invalid Format" : undefined
}

export const siteNameFormat = (value: string) => {
  return !SITE_NAME_PATTERN.test(value) ? "Invalid Format" : undefined
}

export const siteUrlFormat = (value: string) => {
  return !SITE_URL_PATTERN.test(value) ? "Invalid Format" : undefined
}

export const phoneFormat = (value: string) => {
  return !PHONE_PATTERN.test(value) ? "Phone Number should be 10 digits" : undefined
}

export const siteSelected = (role: any) => (value: any) => {
  if (hasCustomerRole(role)) {
    return undefined;
  }
  return value && value.length > 0 ? undefined : "User must be assigned to atleast 1 site"
};

export const pswdRule = (value: string) => {
  const atleastOneDigit = /(?=.*\d)/
  const atleastOneLowerCase = /(?=.*[a-z])/
  const atleastOneUpperCase = /(?=.*[A-Z])/
  const atleastOneSpecialChar = /(?=.*[^A-Za-z0-9])/
  const atleastEightChar = /.{8,}/

  if (!atleastOneDigit.test(value)) {
    return "Password should contain atleast one digit"
  }
  if (!atleastOneLowerCase.test(value)) {
    return `Password should contain atleast\none lowercase letter`
  }
  if (!atleastOneUpperCase.test(value)) {
    return `Password should contain atleast\none uppercase letter`
  }
  if (!atleastOneSpecialChar.test(value)) {
    return `Password should contain atleast\none special character or punctuation mark`
  }
  if (!atleastEightChar.test(value)) {
    return `Password should contain atleast\n8 characters`
  }
  if (!atleastEightChar.test(value)) {
    return `Password should contain atleast\n8 characters`
  }
  return undefined
}

export const hasDuplicateSiteNames = (sites: SiteRequest[]) => {
  const siteNames = sites.map(site => site?.siteName?.trim()?.toLowerCase());
  const uniqueSiteNames = new Set(siteNames);
  return siteNames.length !== uniqueSiteNames.size ? "Site names must be unique" : undefined;
};
