import { CompactTableCell, StyledExpandButton } from "@/styles/theme/styledComponents";
import { Icon } from "@telus-uds/components-web";
import { CaretDown, CaretUp } from '@telus-uds/palette-allium/build/web/icons';
import { ExpandableProps } from "../DataTableTypes";

const TableExpandableButton = ({ rowKey, expandedRows, handleExpansion }: ExpandableProps) => {
  return (
    <CompactTableCell>
      <StyledExpandButton
        onClick={() => {
          handleExpansion(rowKey);
        }}
      >
        {expandedRows[rowKey] ? (
          <Icon
            variant={{ size: "micro", color: "subtle" }}
            icon={CaretUp}
            accessibilityLabel={"Click to close dropdown"}
          />
        ) : (
          <Icon
            variant={{ size: "micro", color: "subtle" }}
            icon={CaretDown}
            accessibilityLabel={"Click to open dropdown"}
          />
        )}
      </StyledExpandButton>
    </CompactTableCell>
    )
  }

export default TableExpandableButton
