import { CustomerUserResponse } from '@/api/index'
import useAppSorting, { Direction } from '@/hooks/useAppSorting'
import palette from '@/styles/theme'
import { TableType } from '@/types/Table'
import { A11yText, Icon, StackView } from '@telus-uds/components-web'
import {
  CaretDown, CaretUp
} from '@telus-uds/palette-allium/build/web/icons'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
interface DataTableSortButtonsProps {
  sortKey: keyof CustomerUserResponse
  columnSortKey?: string
  tableType: TableType
}

const getSortClass = (direction: Direction | undefined) => {
  return direction === undefined ? '' : direction + ' SORTED'
}

const debounceTime = 300; // milliseconds

const DataTableSortButtons: React.FC<DataTableSortButtonsProps> = ({ sortKey, tableType }) => {
  const {
    onSortChange,
    direction
  } = useAppSorting(sortKey, tableType, useDispatch())

  const handleSortChange = async (newDirection: Direction) => {
    setTimeout(async () => {
      await onSortChange(newDirection);
    }, debounceTime); // Delay execution by 300 milliseconds
  };

  return (
    <StackView>
      {(Direction.ASC === direction) && (
        <CaretButton className={getSortClass(direction)} onClick={() => handleSortChange(Direction.DESC)}>
          <Icon variant={{ size: 'micro' }} icon={CaretDown} />
          {<A11yText text=", sort column descending" />}
        </CaretButton>
      )}
      {(Direction.DESC === direction) && (
        <CaretButton className={getSortClass(direction)} onClick={() => handleSortChange(Direction.ASC)}>
          <Icon variant={{ size: 'micro' }} icon={CaretUp} />
          {<A11yText text=", sort column ascending" />}
        </CaretButton>
      )}
    </StackView>
  )
}

const CaretButton = styled.button`
  margin: 0;
  padding: 4px;
  border: none;
  width: 24px;
  height: 24px;
  background-color: unset;
  cursor: pointer;
  transition: background-color 0.1s linear;
  border-radius: 4px;

  &.SORTED {
    & svg {
      fill: ${palette.color.black} !important;
    }
    background-color: ${palette.color.greyMystic} !important;
    &:hover {
      background-color: ${palette.color.greyMystic} !important;
    }
  }

  &:hover {
    background-color: ${palette.color.greyMystic} !important;
    & svg {
      fill: ${palette.color.black} !important;
    }
  }

  & svg {
    fill: ${palette.color.black} !important;
  }
`

export default DataTableSortButtons
