import { FlexGrid, Spacer } from "@telus-uds/components-web"
import { SummaryCard } from "../Dashboard/AccountCreation/Steps/Summary"
import LabeledValue from "./LabeledValue"
import React from "react"

export interface DataRow {
  key: string
  value: string | number
  active?: boolean
}[]
export interface ColumnLayoutProp{
  rows: DataRow[][]
  title?: string
}

export const ColumnLayout = (data: ColumnLayoutProp) => {
    return (
      <>
        <SummaryCard name={data.title} length={1} data={null}>
          <FlexGrid outsideGutter={false}>
            {data.rows.map((arr: any, rowIndex: any) =>
              (<FlexGrid.Row key={rowIndex}>
                {arr.map(({ key, value, active }: {key: string, value: string, active: boolean}, colIndex: any) => (
                  <React.Fragment key={key}>
                    <FlexGrid.Col xs={12} sm={12} md={6} lg={6} xl={6}>
                      <LabeledValue label={key} value={value} active={active}/>
                    </FlexGrid.Col>
                  </React.Fragment>
                ))}
              </FlexGrid.Row>)
            )}
          </FlexGrid>
        </SummaryCard>
        <Spacer space={4} />
      </>
    );
  };
export default ColumnLayout
