import {
  CheckboxGroup,
  DatePicker,
  FlexGrid,
  Spacer,
  StackView,
  TextInput,
  Typography,
} from "@telus-uds/components-web";
import React, { useEffect, useState, useContext } from "react";
import { Field, useForm } from "react-final-form";
import moment from "moment";
import { SearchProps } from ".";
import FormContext from "./FormContext"; // Adjust the import path as needed
import { CustomerApiService, UsimStatus } from "@/api/index";

const DeviceSearch = ({ handleChange, customerId }: SearchProps) => {
  const { resetKey } = useContext(FormContext);
  const [deviceSites, setDeviceSites] = useState([]);

  // console.log("DEVICES Customer ID:", customerId);

  // const customerId = customerId; // This should be dynamically set based on your application's context or passed as a prop

  useEffect(() => {
    const fetchSiteData = async () => {
      const response = await CustomerApiService.get(customerId);
      // console.log("RESPONSE FOR SITES:", response.sites);
      // Transform response to match the expected format for deviceSites
      const transformedSites = response.sites.map((userSite: any) => ({
        id: userSite.id.toString(),
        label: userSite.siteName,
      }));
      setDeviceSites(transformedSites);
    };

    fetchSiteData();
  }, [customerId]); // Re-fetch when customerId changes

  // console.log("DEVICES SITE DATA:", deviceSites);

  const deviceStatus = [
    { id: UsimStatus.ACTIVATED, label: "Activated" },
    { id: UsimStatus.DEACTIVATED, label: "Deactivated" },
    { id: UsimStatus.INVENTORY, label: "Inventory" },
    { id: UsimStatus.BLOCKED, label: "Blocked" }
  ];
  // const deviceSites = [
  //   { id: "for_thills", label: "Fort Hills" },
  //   { id: "ontario", label: "Ontario" },
  //   { id: "oil_sand", label: "Oil Sand" },
  //   { id: "quebec", label: "Quebec" },
  // ];

  return (
    <FlexGrid outsideGutter={false} limitWidth={false}>
      <FlexGrid.Row verticalAlign="middle">
        <FlexGrid.Col xs={12} md={4}>
          <Field
            name="name"
            render={({ input, meta }) => (
              <TextInput
                label="Name"
                value={input.value}
                onChange={(newValue: string) => {
                  handleChange(input)(newValue);
                }}
              />
            )}
          />
        </FlexGrid.Col>
        <FlexGrid.Col xs={12} md={4}>
          <Field
            name="imsi"
            render={({ input, meta }) => (
              <TextInput
                label="IMSI"
                value={input.value}
                onChange={(newValue: string) => {
                  handleChange(input)(newValue);
                }}
              />
            )}
          />
        </FlexGrid.Col>
        <FlexGrid.Col xs={12} md={4}>
          <Field
            name="msisdn"
            render={({ input, meta }) => (
              <TextInput
                label="MSISDN"
                value={input.value}
                onChange={(newValue: string) => {
                  handleChange(input)(newValue);
                }}
              />
            )}
          />
        </FlexGrid.Col>
      </FlexGrid.Row>
      <Spacer space={2} />
      <FlexGrid.Row verticalAlign="middle">
        <FlexGrid.Col xs={12} md={4}>
          <Field
            name="profileName"
            render={({ input, meta }) => (
              <TextInput
                label="Profile Name"
                value={input.value}
                onChange={(newValue: string) => {
                  handleChange(input)(newValue);
                }}
              />
            )}
          />
        </FlexGrid.Col>
        {/* <FlexGrid.Col xs={12} md={4}>
          <Field
            name="dateActivated"
            render={({ input, meta }) => (
              <DatePicker
                id="Date Activated"
                label="Date Activated"
                date={input.value === '' ? null : moment.utc(input.value)}
                onDateChange={(date) => {
                  handleChange(input)(moment.utc(date).startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZ'))
                }}
                isDayDisabled={() => false}
              />
            )}
          />
        </FlexGrid.Col>
        <FlexGrid.Col xs={12} md={4}>
          <Field
            name="dateAdded"
            placeholder="DD/MM/YYYY"
            render={({ input, meta }) => (
              <DatePicker
                id="Date Added"
                label="Date Added"
                date={input.value === '' ? null : moment.utc(input.value)}
                onDateChange={(date) => {
                  handleChange(input)(moment.utc(date).startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZ'))
                }}
                isDayDisabled={() => false
                }
              />
            )}
          />
        </FlexGrid.Col> */}
      </FlexGrid.Row>
      <Spacer space={2} />
      <FlexGrid.Row verticalAlign="top">
        <FlexGrid.Col xs={12} md={4}>
          <Field
            name="siteName"
            render={({ input }) => (
              <StackView space={2}>
                <Typography variant={{ bold: true }}>Site</Typography>
                <CheckboxGroup
                  key={`deviceSites-${resetKey}`}
                  initialCheckedIds={[]}
                  checkedId={input.value || []}
                  onChange={(newCheckedIds: string[]) => {
                    const selectedLabels = newCheckedIds.map(
                      (id) =>
                        deviceSites.find((status) => status.id === id)?.id
                    );
                    input.onChange(selectedLabels); // React Final Form's input.onChange will handle state update
                    handleChange(input)(selectedLabels.join(","));
                  }}
                  items={deviceSites}
                />
              </StackView>
            )}
          />
        </FlexGrid.Col>
        <FlexGrid.Col xs={12} md={4}>
          <Field
            name="status"
            render={({ input }) => (
              <StackView space={2}>
                <Typography variant={{ bold: true }}>Status</Typography>
                <CheckboxGroup
                  key={`deviceStatus-${resetKey}`}
                  initialCheckedIds={[]}
                  checkedId={input.value || []}
                  onChange={(newCheckedIds: string[]) => {
                    // input.onChange(newCheckedIds); // React Final Form's input.onChange will handle state update
                    // handleChange(input)(newCheckedIds);
                    const selectedLabels = newCheckedIds.map(
                      (id) =>
                        deviceStatus.find((status) => status.id === id)?.id
                    );
                    input.onChange(selectedLabels); // React Final Form's input.onChange will handle state update
                    handleChange(input)(selectedLabels.join(","));
                  }}
                  items={deviceStatus}
                />
              </StackView>
            )}
          />
        </FlexGrid.Col>
      </FlexGrid.Row>
    </FlexGrid>
  );
};

export default DeviceSearch;
