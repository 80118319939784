/* eslint-disable @typescript-eslint/semi */
import { setTableState, useTableState } from '@/store/customerManagement'
import { useCallback, useMemo } from 'react'
import { TableType } from '@/types/Table'

export enum Direction {
  ASC, DESC
}

const useAppSorting = (key?: string, tableType?: TableType, dispatch?: any) => {
  // const currentPageable: Pageable = useCurrentCustomerPage()
  const { currentPage, sortKeys } = useTableState(tableType)

  const {
    direction,
    currentKey,
  } = useMemo(() => {
    if (sortKeys?.[0]) {
      const sort = (sortKeys?.[0]).split(',') as string[] // Add type assertion here
      const fullKey = sort?.[0]
      const currentKey = fullKey.split('.').pop() // Extract the key part
      let direction: Direction | undefined

      const effectiveKey = key
      // console.log(`fullKey:${fullKey}, currentSortClomnKey:${columnSortKey}, currentAlias:${currentAlias}, effectiveKey:${effectiveKey}`)
      if (currentKey === effectiveKey) {
        direction = (sort?.[1] === 'ASC') ? Direction.DESC : Direction.ASC
      }
      if (!currentKey) {
        direction = Direction.ASC
      }

      return {
        direction,
        currentKey,
      }
    }
    // Return default values if no sort keys are available
    return {
      direction: undefined,
      currentKey: undefined,
    };
  }, [key, sortKeys])

  /**
   * Handles sort change via the targetDirection argument.
   *
   * If the currentKey key in state is equal to the key being passed in
   * this function will instead act as a toggle.
   */
  const onSortChange = useCallback(async (targetDirection: Direction) => {
    let sort = ''
    const effectiveKey = key
    const sortKey = effectiveKey
    if (currentKey === effectiveKey) {
      const dir = (direction === targetDirection && targetDirection === Direction.DESC) ? Direction.ASC : (direction || Direction.ASC);
      sort = `${sortKey},${Direction[dir]}`
    } else {
      sort = `${sortKey},${Direction[targetDirection]}`
    }
    if (!currentKey) {
      const dir = (direction === targetDirection && targetDirection === Direction.DESC) ? Direction.ASC : direction;
      sort = `${sortKey},${Direction[dir]}`
    }
    console.log(`sort:${sort}`)
    console.log(`currentKey:${currentKey}, effectiveKey:${effectiveKey}, direction:${Direction[direction]}, targetDirection:${Direction[targetDirection]}`)
    console.log("tableType", tableType)
    dispatch(setTableState({ id: tableType, state: { sortKeys: [sort] } }))
  }, [currentKey, direction, dispatch, key, tableType])

  return {
    onSortChange,
    direction
  }
}

export default useAppSorting
