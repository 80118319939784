/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { UserStatus } from "@/api/index";
import {
  AdvancedSearchParams,
  AppSettings,
  CustomerSearchFields,
  DeviceSearchFields,
  setAdvancedSearchPanelState,
  setAdvancedSearchParams,
  setAppSettings,
  setCurrentPageable,
  setGeneralSearchQuery,
  useAppSettings,
  useGeneralSearchQuery,
  UserSearchFields
} from "@/store/customerManagement";
import { getStyling, tableTopButtonTheme } from "@/styles/theme/themes";
import {
  Button,
  Search,
  Spacer,
  StackWrap
} from "@telus-uds/components-web";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";

// Initial state definitions for each search field type
const resetUserSearchFields: UserSearchFields = {
  name: "",
  role: { label: '' },
  status: '' as UserStatus,
  username: "",
};

const resetCustomerSearchFields: CustomerSearchFields = {
  customerName: "",
  id: "",
  ban: "",
  customerStatus: [],
};

const resetDeviceSearchFields: DeviceSearchFields = {
  name: "",
  imsi: "",
  msisdn: "",
  profileName: "",
  siteName: [],
  status: [],
  dateActivated: "",
  createdAt: "",
};

// Complete reset object for AdvancedSearchParams
export const resetAdvancedSearchParams = (): AdvancedSearchParams => ({
  ...resetUserSearchFields,
  ...resetCustomerSearchFields,
  ...resetDeviceSearchFields
});

const DataTableSearch = () => {
  const { searchQuery } = useGeneralSearchQuery();
  const [search, setSearch] = useState<string>("");
  // const [key, setKey] = useState(searchKey);
  const dispatch = useDispatch();
  const appSettings: AppSettings = useAppSettings();
  const searchRef = useRef(null);
  const sliceStatus = [
    { label: "Activated" },
    { label: "Deactivated" },
    { label: "Requested" },
    { label: "Terminated" },
  ];
  useMemo(() => searchQuery, [searchQuery]);
  useEffect(() => setSearch(searchQuery), [searchQuery]);

  const onClear = useCallback(
    () => {
      dispatch(setGeneralSearchQuery({ searchQuery: "" }))
      dispatch(setCurrentPageable({ page: 0 }));
    },
    [dispatch]
  );


  const onSubmit = useCallback(() => {
    // console.log("submitting");
    dispatch(setAppSettings({ advancedSearchPanelState: false} as AppSettings));
    // dispatch(setAdvancedSearchParams(resetAdvancedSearchParams));
    handleSubmit();
    return dispatch(setGeneralSearchQuery({ searchQuery: search }));
  }, [dispatch, search]);

  const handleSubmit = () => {
    if (searchRef.current?.focus) {
      setTimeout(() => {
        searchRef.current.focus();
      }, 100);
    }
  };

 const onAdvancedSearch = useCallback(() => {
    dispatch(setCurrentPageable({ page: 0 })); // Reset the page number to 0
    dispatch(setAdvancedSearchPanelState());
    dispatch(setGeneralSearchQuery({ searchQuery: "" }));
    dispatch(setAdvancedSearchParams(resetAdvancedSearchParams()));
  }, [dispatch]);

  return (
    <StackWrap direction="row" space={2}>
      <Search
        copy="en"
        onClear={onClear}
        value={search}
        onChange={(v: string) => setSearch(v)}
        onSubmit={onSubmit}
        ref={searchRef}
      />
      <Spacer direction="row" space={2} />
      <Button
        tokens={getStyling(tableTopButtonTheme)}
        onPress={onAdvancedSearch}
        variant={{ text: true }}
      >
        Advanced Search
      </Button>
    </StackWrap>
  );
};

export default DataTableSearch;
