import { TableType } from '@/types/Table'
import { FlexGrid, Spacer } from '@telus-uds/components-web'
import React, { useRef } from 'react'
import TablePagination from './TablePagination'

interface TableFooterProps {
  tableType: TableType
  totalPages: number
  summaryComponent?: JSX.Element
}
const TableFooter = ({ tableType, summaryComponent, totalPages}: TableFooterProps) => {
  const scrollRef = useRef(null)

  return (
    <div style={{ marginRight: '8px' }}>
      <Spacer space={3} />
      <FlexGrid outsideGutter={false} limitWidth={false}>
        <FlexGrid.Row distribute="between">
          <FlexGrid.Col xs={4}>
          <TablePagination totalPages={totalPages} scrollTo={scrollRef} tableType={tableType}/>
          </FlexGrid.Col>
          <FlexGrid.Col xs={8}> 
            {summaryComponent || <></>}
          </FlexGrid.Col>
        </FlexGrid.Row>
      </FlexGrid>
    </div>
  )
}

export default TableFooter
