import { CustomerTableData } from './TelusHomePage'
import ColumnLayout, { ColumnLayoutProp } from '@/components/ToolBar/ColumnLayout'
import { Box } from '@telus-uds/components-web'

const CustomerExpandedView = ({data}:{data: CustomerTableData}) => {
  const active = data?.customerStatus?.toUpperCase() === 'ACTIVE'
  const customerDetails: ColumnLayoutProp = {
    title: 'Customer Details',
    rows: [
      [ // ROW 1
        { key: 'Customer Id', value: data?.id },
        { key: 'Customer Name', value: data?.customerName },
        { key: 'Status', value: data?.customerStatus, active },
        { key: 'BAN', value: data?.ban }
      ]
    ]
  }
  const primaryContact: ColumnLayoutProp = {
    title: 'Primary Contact',
    rows: [
      [ // ROW 1
        { key: 'Contact Name', value: data?.primaryContact?.contactName },
        { key: 'Phone', value: data?.primaryContact?.phone },
        { key: 'Description', value: data?.primaryContact?.description },
        { key: 'Email', value: data?.primaryContact?.emailId }
      ]
    ]
  }
  const billingDetails: ColumnLayoutProp = {
    title: 'Billing Details',
    rows: [
      [ // ROW 1
        { key: 'Address', value: data?.billingDetails?.address },
        { key: 'Country', value: data?.billingDetails?.country },
        { key: 'City', value: data?.billingDetails?.city },
        { key: 'Postal Code', value: data?.billingDetails?.postalCode },
        { key: 'Province', value: data?.billingDetails?.province },
        { key: 'Billable', value: data?.billingDetails?.billable ? 'Yes' : 'No' }
      ]
    ]
  }
  const shippingDetails: ColumnLayoutProp = {
    title: 'Shipping Details',
    rows: [
      [ // ROW 1
        { key: 'Address', value: data?.shippingDetails?.address },
        { key: 'Country', value: data?.shippingDetails?.country },
        { key: 'City', value: data?.shippingDetails?.city },
        { key: 'Postal Code', value: data?.shippingDetails?.postalCode },
        { key: 'Province', value: data?.shippingDetails?.province }
      ]
    ]
  }

  return (
    <Box space={4}>
        {ColumnLayout(customerDetails)}
        {ColumnLayout(primaryContact)}
        {ColumnLayout(billingDetails)}
        {ColumnLayout(shippingDetails)}
    </Box>
  )
}

export default CustomerExpandedView
