import { TableType } from "@/types/Table";
import { Checkbox, Table } from "@telus-uds/components-web";
import { ColumnDefinitionType } from "../DataTableTypes";
import DataTableHeaderCell from "./DataTableHeaderCell";
import { useSelectedRows } from "./SelectedRowsProvider";
import { Direction } from "@/hooks/useAppSorting";


interface TableRowHeaderProps<T, K extends keyof T> {
  columns: Array<ColumnDefinitionType<T, K>>;
  isSelectable?: boolean;
  isSortable?: boolean;
  defaultSortKey?: string;
  defaultSortDirection?: Direction;
  tableType?: TableType;
  isExpandable?: boolean;
  isLoading?: boolean;
}
  
const TableRowHeader = <T, K extends keyof T>({ columns, isSelectable, isSortable, defaultSortKey, defaultSortDirection, isExpandable, tableType, isLoading }: TableRowHeaderProps<T, K>) => {
  const {isAllSelected, onSelectAll} = useSelectedRows()
  return (
    <Table.Header>
      <>
        {isSelectable && !isLoading && <DataTableHeaderCell>
            <Checkbox
              checked= {isAllSelected || false}
              onChange= {() => onSelectAll()}
            />
          </DataTableHeaderCell>}
        {isExpandable && !isLoading && <DataTableHeaderCell/>}
        {columns.map((column, index) => (
          <DataTableHeaderCell key={index}
          {...(isSortable && column.isSortable !== false &&{
            sortKey: column.columnSortKey || column.key,
            defaultSortKey,
            defaultSortDirection,
            tableType
          })}
          >{column.header}</DataTableHeaderCell>
        ))}
      </>
    </Table.Header>
  )
}
  
export default TableRowHeader;
