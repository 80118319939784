import { Select } from '@telus-uds/components-web'
import { type MutableRefObject } from 'react'
import { useDispatch } from 'react-redux'
import { TableType } from '@/types/Table'
import { setCurrentPageable, setTableState, useTableState } from '@/store/customerManagement';

interface TableSizeSelectProps {
  tableType: TableType
}

const TableSizeSelect: React.FC<TableSizeSelectProps> = ({ tableType }: TableSizeSelectProps) => {
  const dispatch = useDispatch();

  const { pageSize } = useTableState(tableType);
  // console.log('tableType', tableType)
  // console.log('******pageSize', pageSize)

  return (
    <div style={{ alignItems: 'center', display: 'flex' }}>
      <Select
        value={String(pageSize)}
        onChange={(value: string) => {
          dispatch(setTableState({ id: tableType, state: { pageSize: parseInt(value) } })) // set new page size and reset to first page
          dispatch(setCurrentPageable({page: 0, size: +value}))
        }}
      >
        <Select.Item value="10">10</Select.Item>
        <Select.Item value="25">25</Select.Item>
        <Select.Item value="50">50</Select.Item>
        <Select.Item value="100">100</Select.Item>
      </Select>
    </div>
  )
}

export default TableSizeSelect
