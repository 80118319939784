// import { TableState } from './../store/customerManagement';
import {
  AppSettings,
  CustomerSearchFields,
  setAppSettings,
  setCurrentPageable,
  setTableState,
  useAdvancedSearchPanelState,
  useAdvancedSearchParams,
  useCurrentPageable,
  useGeneralSearchQuery,
  useTableState,
} from "@/store/customerManagement";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { CustomerApiService } from "../generated";
import { setNotification } from "@/store/submitNotification";
import { TableType } from "@/types/Table";

interface CustomerSearchParams {
  page?: number;
  size?: number;
  sort?: Array<string>;
  id?: string;
  name?: string;
  ban?: number;
  status?: string[];
  searchTerm?: string;
}

export const useCustomerSearch = (
) => {
  const dispatch = useDispatch();
  const { searchQuery } = useGeneralSearchQuery();
  const [lastSimpleSearch, setLastSimpleSearch] = useState(searchQuery);
  const searchParams = useAdvancedSearchParams() as CustomerSearchFields;
  const advancedSearchOpen = useAdvancedSearchPanelState();
  const lastSearchParams = useRef(searchParams);
  const { pageSize, sortKeys } = useTableState(TableType.CUSTOMER_LIST);
  const {page: pageNumber} = useCurrentPageable();

  const queryParams = useMemo(() => {
    const baseParams: CustomerSearchParams = {
      page: pageNumber,
      size: pageSize,
      sort: sortKeys,
      searchTerm: searchQuery.trim(),
    };
    const params: any = { ...baseParams }; // Use 'any' temporarily to avoid TypeScript errors.
    // Remove empty fields to avoid sending them in the API call
    Object.keys(params).forEach((key) => {
      if (
        params[key] === "" ||
        params[key] === undefined ||
        (Array.isArray(params[key]) && params[key].length === 0)
      ) {
        delete params[key];
      }
    });

    if (advancedSearchOpen) {
      // Prepare and clean advanced parameters
      const advancedParams: { [key: string]: any } = {
        name: searchParams.customerName?.trim(),
        id: searchParams.id?.trim(),
        ban: searchParams.ban?.trim(),
        status:
          searchParams.customerStatus?.length > 0
            ? searchParams.customerStatus
            : undefined,
      };

      // Append advanced parameters if they are not empty or undefined
      Object.entries(advancedParams).forEach(([key, value]) => {
        if (
          value !== undefined &&
          value !== "" &&
          !(Array.isArray(value) && value.length === 0)
        ) {
          params[key] = value;
        }
      });
    }

    return params as CustomerSearchParams; // Cast back to the proper type
  }, [pageNumber, pageSize, searchQuery, searchParams, advancedSearchOpen, sortKeys]);

  const fetchData = useCallback(async (params: CustomerSearchParams) => {
    // console.log("Fetching data with params:", params)
    try {
      const data = await CustomerApiService.list(
        params.page,
        params.size,
        params.sort,
        params.id,
        params.name,
        params.ban,
        params.status,
        params.searchTerm
      );

      // console.log("Data received:", data);
      dispatch(setTableState({ id: TableType.CUSTOMER_LIST, state: { currentPage: data } }))
      // dispatch(setCurrentCustomerPage({...data, sortKeys: params.sort}));
    } catch (error) {
      console.error("Error fetching customers:", error);
      dispatch(
        setNotification({ message: "Error fetching data", type: "error" })
      );
    } finally {
      dispatch(
        setAppSettings({ isSubmitted: true, isLoading: false } as AppSettings)
      );
    }
  }, [dispatch]);

  useEffect(() => {
    if (searchQuery !== lastSimpleSearch) {
      dispatch(setCurrentPageable({ page: 0 })) // Reset to first page if the search query has changed
      setLastSimpleSearch(searchQuery);
    }

    if (advancedSearchOpen && lastSearchParams.current !== searchParams) {
      dispatch(setCurrentPageable({ page: 0 }))
      lastSearchParams.current = searchParams;
    }

    fetchData(queryParams);
  }, [searchQuery, pageNumber, lastSimpleSearch, fetchData, advancedSearchOpen, searchParams, queryParams, dispatch]);

};
