import { Role } from '@/api/index';
import TableSizeSelect from '@/components/Table/TableHeader/TableSizeSelect';
import TextIconButton from '@/components/ToolBar/TextIconButton';
import { useCurrentUserRole } from '@/store/customerManagement';
import { tableTopButtonTheme, tableTopIconTheme } from '@/styles/theme/themes';
import { isTelusAdmin } from '@/types/RoleMapping';
import { TableType } from '@/types/Table';
import { StackView } from '@telus-uds/components-web';
import { Add, Users } from '@telus-uds/palette-allium/build/web/icons';
import { useRouter } from 'next/router';

const CustomerTableButtons = () => {
  const router = useRouter()
  const currentUserRole: Role = useCurrentUserRole();
  const redirectToAccountCreation = async () => {
    await router.push('/customer-creation')
  };
  const redirectToTelusUserManagement = async () => {
    await router.push('/telus-users')
  };
  if (isTelusAdmin(currentUserRole)) {
      return (
        <StackView space={4} tokens={{ justifyContent: 'flex-end' }} divider direction="row">
          <TextIconButton
            content='Manage Telus Users'
            onClick={redirectToTelusUserManagement}
            theme={tableTopButtonTheme}
            iconTheme={tableTopIconTheme}
            icon={Users}
          />
          <TextIconButton
            content='Create Customer'
            onClick={redirectToAccountCreation}
            theme={tableTopButtonTheme}
            iconTheme={tableTopIconTheme}
            icon={Add}
          />
          <TableSizeSelect tableType={TableType.CUSTOMER_LIST}/>
        </StackView>
      )
  }
}

export default CustomerTableButtons
