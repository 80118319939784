import { TableType } from '@/types/Table'
import { HeadersProps } from '@/types/global'
import { Skeleton, Table } from '@telus-uds/components-web'
import { useMemo } from 'react'
import { ColumnDefinitionType } from '../DataTableTypes'
interface TableSkeletonProps <T, K extends keyof T> {
  headers?: Array<ColumnDefinitionType<T, K>> | string[] | HeadersProps[]//remove the string[] after cleanup the connection history table
  tableType?: TableType
}
const TableSkeleton = <T, K extends keyof T>({ headers, tableType}: TableSkeletonProps<T, K>) => {
  const rowArray = useMemo(() => new Array(3).fill(''), [])
  return (
    <Table.Body>
      {rowArray?.map((_, index: number) => (
        <Table.Row dataSet={{ testId: 'test' }} key={index}>
          <>
            {headers.map((_, index: number) => (<Table.Cell key={index}>
              <Skeleton characters={3} />
            </Table.Cell>))}
          </>
        </Table.Row>
      ))}
    </Table.Body>
  )
}

export default TableSkeleton
