import { StyledLabelValueWrapper } from '@/styles/theme/styledComponents'
import { getStyling, labelTheme } from '@/styles/theme/themes'
import { Typography } from '@telus-uds/components-web'
import { Status } from './Status'
interface LabeledValueProps{
  label: string
  value: string
  active?: boolean
  isHeader?: boolean
}

const LabeledValue = ({ label, value, active, isHeader = false }: LabeledValueProps) => {
  return (
    <StyledLabelValueWrapper>
      <Typography variant={isHeader ? { bold: true, size: 'display1' } : {}}  tokens={getStyling(labelTheme)}>{label}</Typography>
      { label?.toLowerCase() === 'status'
        ? <Status message={value} active={active}/>
        : <Typography variant={isHeader ? { bold: true, size: 'h1' } : { colour: 'h1' }} tokens={isHeader ? getStyling(labelTheme) : {}}>{value}</Typography>
      }
    </StyledLabelValueWrapper>
  )
}

export default LabeledValue
