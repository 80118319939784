import useAppSorting, { Direction } from '@/hooks/useAppSorting'
import { StackView, Table, Typography } from '@telus-uds/components-web'
import { PropsWithChildren, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import styles from '../Table.module.scss'
import paletteAllium from "@telus-uds/palette-allium";
import { TableType } from '@/types/Table'
import DataTableSortButtons from './DataTableSortButtons'
import { setTableState, useTableState } from '@/store/customerManagement'

interface DataTableHeaderCellProps {
  sortKey?: any
  tableType?: TableType
  defaultSortKey?: string
  defaultSortDirection?: Direction
}

const DataTableHeaderCell: React.FC<PropsWithChildren<DataTableHeaderCellProps>> = ({
  sortKey,
  defaultSortKey,
  defaultSortDirection,
  tableType,
  children,
}) => {
  const dispatch = useDispatch()
  const { onSortChange, direction } = useAppSorting(sortKey, tableType, useDispatch());
  const { sortKeys } = useTableState(tableType);
  const linkRef = useRef<HTMLAnchorElement>(null)

  useEffect(() => {
    if (!sortKeys) {
      const sort = `${defaultSortKey},${defaultSortDirection === Direction.ASC ? 'ASC' : 'DESC'}`
      dispatch(setTableState({ id: tableType, state: { sortKeys: [sort] } }))
    }
  }, [tableType]);

  const handleKeyDown = async (event: React.KeyboardEvent<HTMLAnchorElement>, sortDirection: Direction) => {
    if (event.key === 'Enter') {
      await onSortChange(sortDirection);
      if (linkRef.current) {
        linkRef.current.focus();
      }
    }
  };

  return (
    <Table.Cell tokens={{ cellBackground: `${paletteAllium.color.greyAthens}` }}>
      <StackView direction="row" space={2}>
        {(Direction.ASC === direction) && (
          sortKey && (
            <Typography>
              <a
                ref={linkRef}
                className={styles.customLink}
                tabIndex={0}
                onClick={async () => {
                  await onSortChange(Direction.DESC);
                  if (linkRef.current) {
                    linkRef.current.focus();
                  }
                }}
                onKeyDown={(event) => handleKeyDown(event, Direction.DESC)}
              >
                {children}
              </a>
            </Typography>
          )
        )}
        {(Direction.DESC === direction || direction === undefined) && (
          sortKey && (
            <Typography>
              <a
                ref={linkRef}
                className={styles.customLink}
                tabIndex={0}
                onClick={async () => {
                  await onSortChange(Direction.ASC);
                  if (linkRef.current) {
                    linkRef.current.focus();
                  }
                }}
                onKeyDown={(event) => handleKeyDown(event, Direction.ASC)}
              >
                {children}
              </a>
            </Typography>
          )
        )}
        {!sortKey && <Typography>{children}</Typography>}

        {sortKey && (
          <DataTableSortButtons sortKey={sortKey} tableType={tableType} />
        )}
      </StackView>
    </Table.Cell>
  )
}

export default DataTableHeaderCell
