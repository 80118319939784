import { NetworkConfigurationService, PageUsimDataTableViewResponse, UsimConnectionStatusResponse, UsimDataTableViewResponse, UsimSessionInfo, UsimSiteEntryResponse, UsimStatus } from "@/api/index";
import { Table } from "@/components/Table";
import { ColumnDefinitionType } from "@/components/Table/DataTableTypes";
import { ColumnLayoutProp } from "@/components/ToolBar/ColumnLayout";
import LabeledValue from "@/components/ToolBar/LabeledValue";
import { LocalTime } from "@/components/ToolBar/LocalTime";
import { TableType } from "@/types/Table";
import { handleApiError } from "@/utils/error-handler";
import {
  Button,
  Card,
  Divider,
  FlexGrid,
  Icon,
  Spacer,
  StackView,
  StackWrap,
  Typography
} from "@telus-uds/components-web";
import {
  LocationMap
} from "@telus-uds/palette-allium/build/rn/icons";
import { useRouter } from "next/router";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import TableButtons from "./Table/TableButtons";


export const CardWrapper = styled.div`
  z-index: 0 !important;
`

interface DetailsProps {
  simDetails: UsimSiteEntryResponse
  refreshCallback: () => void
  modalOpen: boolean
  setModalOpen: (data: boolean) => void
}
export const Details = (data: ColumnLayoutProp) => {
  return (
    <>
      <FlexGrid limitWidth={false}>
        {data.rows.map((arr: any, rowIndex: any) =>
          (<FlexGrid.Row key={rowIndex}>
            {arr.map(({ key, value, active }: {key: string, value: string, active: boolean}, colIndex: any) => (
              <React.Fragment key= {colIndex}>
                <FlexGrid.Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <LabeledValue label={key} value={value} active={active}/>
                </FlexGrid.Col>
              </React.Fragment>
            ))}
          </FlexGrid.Row>)
        )}
      </FlexGrid>
    <Spacer space={4} />
    </>
  );
};

const DeviceDetails = ({ simDetails, refreshCallback, modalOpen, setModalOpen }: DetailsProps) => {
  const router = useRouter()
  const dispatch = useDispatch()
  const [sessionData, setSessionData] = useState<UsimSessionInfo[]>([])
  const [connectionStatusData, setConnectionStatusData] = useState<UsimConnectionStatusResponse[]>([])
  const { id: customerId, "site-id": siteId, deviceId } = router.query;
  const [refresh, setRefresh] = useState(0)
  const selectedData: UsimDataTableViewResponse = {
    siteId: simDetails?.siteId,
    usimId: simDetails?.id,
    imsi: simDetails?.imsi,
    name: simDetails?.name,
    status: simDetails?.status,
    profileName: simDetails?.profileName,
    usimProfileId: simDetails?.profileId,
    msisdn: simDetails?.msisdn,
    description: simDetails?.description,
    dateActivated: simDetails?.dateActivated,
    siteName: simDetails?.siteName,
  }

    const active = simDetails?.status === UsimStatus.ACTIVATED
    const deviceDetails: ColumnLayoutProp = {
      rows: [
        [
          { key: 'IMSI', value: simDetails?.imsi },
          { key: 'MSISDN', value: simDetails?.msisdn },
          { key: 'Device Name', value: simDetails?.name},
          { key: 'Description', value: simDetails?.description },
          { key: 'Status', value: simDetails?.status, active: active },
          { key: 'Date Activated', value: simDetails?.dateActivated ? LocalTime(simDetails?.dateActivated) : 'Not Activated' },
          { key: 'Profile Name', value: simDetails?.profileName },
          { key: 'Date Added', value: LocalTime(simDetails?.dateAdded) }
        ]
      ]
    }

    const connectionStatusColumns: ColumnDefinitionType<UsimConnectionStatusResponse, keyof UsimConnectionStatusResponse>[] = [
      { key: 'ecmState', header: 'ECM state' },
      { key: 'emmState', header: 'EMM (EMM)'},
      {  key: 'eci',  header: 'Cell id'},
      {  key: 'plmn',  header: 'PLMN'},
      {  key: 'tac',  header: 'TAC'},
      {  key: 'imei',  header: 'IMEI'},
      {  key: 'ageOfLocationInfo',  header: 'Age of Location'},
      {  key: 'lastRadioContact',  header: 'Last Radio Contact',  renderer: (value) => LocalTime(value as string)}
    ]
    const sessionInformationColumns: ColumnDefinitionType<UsimSessionInfo, keyof UsimSessionInfo>[] = [
      { key: 'apn', header: 'APN' },
      { key: 'qci', header: 'QCI'},
      {
        key: 'ip',
        header: 'IP Address',
        renderer: (value, rowData) => <>
          {rowData.ip.v4 && `${rowData.ip.v4}\n`}
          {rowData.ip.v6 && rowData.ip.v6}
        </>
      },
      {  key: 'rat',  header: 'RAT'},
      {  key: 'usage',  header: 'Usage'}
    ]

    const fetchSessionInformation = useCallback(async () => {
      console.log("fetching session information...", customerId);
      NetworkConfigurationService.getUsimSessionInfo(
        +customerId,
        +siteId,
        +simDetails?.imsi
      )
        .then((UsimSessionInfoResponse) => {
          if (UsimSessionInfoResponse?.usimSessionInfo) {
            setSessionData(UsimSessionInfoResponse.usimSessionInfo);
          } else {
            setSessionData([]);
          }
        })
        .catch((error) => {
          handleApiError(error, dispatch)
        });
    }, [customerId, dispatch, simDetails?.imsi, siteId]);

    const fetchConnectionStatus = useCallback(async () => {
      console.log("fetching event history...", customerId);
      NetworkConfigurationService.getUsimConnectionStatus(
        +customerId,
        +siteId,
        +simDetails?.imsi
      )
        .then((UsimConnectionStatusResponse) => {
          if (UsimConnectionStatusResponse && Object.keys(UsimConnectionStatusResponse).length > 0) {
            setConnectionStatusData([UsimConnectionStatusResponse]);
          } else {
            setConnectionStatusData([]);
          }
        })
        .catch((error) => {
          handleApiError(error, dispatch)
        });
    }, [customerId, dispatch, +simDetails?.imsi, siteId]);

    useEffect(() => {
      if (customerId && siteId && simDetails?.imsi){
        fetchConnectionStatus()
        fetchSessionInformation()
      }
    }, [ customerId, siteId, simDetails?.imsi ]);

  return (
    <>
      <StackView space={2}>
        <Spacer space={3}/>
        <StackWrap tokens= {{ justifyContent: 'space-between' }} >
          <StackView direction="row" tokenes={{ justifyContent: 'center' }}>
            <Button
              variant={{ priority: "high" }}
              onPress={() => {
                // console.log("refresh button pressed");
                setRefresh(refresh + 1)
                refreshCallback()
              }}
            >
              Refresh
            </Button>
          </StackView>
          <StackView>
            <TableButtons
              tableType={TableType.DEVICE_DETAILS}
              editAction={{
                editable: selectedData.status !== UsimStatus.BLOCKED,
                editStatus: true,
                editProfile: true,
                assignSite: true,
                selectedData: [selectedData]
              }}
              modalOpen={modalOpen}
              setModalOpen={setModalOpen}
              handleToggleModal={() => { setModalOpen(!modalOpen) }}
              refreshCallback={refreshCallback}
            />
          </StackView>
        </StackWrap>


        <CardWrapper>
          <Card>
            <StackView space={2}>
              <StackView direction="row" tokenes={{ justifyContent: 'center' }} space={2}>
                <Icon
                  icon={LocationMap}
                  accessibilityLabel="Map icon"
                  variant={{ color: 'brand', size: 'large' }}
                />
                <Typography block variant={{ size: "h3" }}>
                  {`${simDetails?.name} / ${simDetails?.siteName}`}
                </Typography>
              </StackView>
              <Divider />
              {Details(deviceDetails)}
              <Typography variant={{ size: "h4", colour: "alternative2", bold: true }}>
                Connection Status
              </Typography>
              <Table columns={connectionStatusColumns} data={connectionStatusData} isSearchable={false}/>
              <Spacer space={3}/>
              <Typography variant={{ size: "h4", colour: "alternative2", bold: true }}>
                Session Information
              </Typography>
              <Table columns={sessionInformationColumns} data={sessionData} isSearchable={false}/>
            </StackView>
          </Card>
        </CardWrapper>
      </StackView>

    </>
  );
};

export default DeviceDetails;
