import {
  BulkSimProfileUpdateRequest,
  BulkSimProfileUpdateResponse,
  BulkSimProvisionRequest,
  BulkSimProvisionResponse,
  BulkSimStatusUpdateRequest,
  BulkSimStatusUpdateResponse,
  DeviceSearchResponse,
  NetworkConfigurationService,
  UsimStatus
} from '@/api/index';
import { resetAdvancedSearchParams } from '@/components/Table/TableHeader/DataTableSearch';
import Loader from '@/components/ToolBar/Loader';
import TextIconButton from '@/components/ToolBar/TextIconButton';
import {
  AppSettings,
  setAdvancedSearchParams,
  setAppSettings,
  setCurrentPageable,
  setGeneralSearchQuery,
  useAppSettings,
  useSelectedCustomer,
  useTableState
} from '@/store/customerManagement';
import { setNotification } from '@/store/submitNotification';
import { ModalContentWrapper } from '@/styles/theme/styledComponents';
import { TableType } from '@/types/Table';
import { queryParamsType } from '@/types/global';
import { handleApiError } from '@/utils/error-handler';
import {
  Button,
  Checkbox,
  FlexGrid,
  Modal,
  RadioGroup,
  Select,
  Skeleton,
  Spacer,
  StackView,
  StackWrap,
  Typography
} from '@telus-uds/components-web';
import arrayMutators from 'final-form-arrays';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useDispatch } from 'react-redux';
import { DeviceTableData } from '.';
import { required } from '../../AccountCreation/validation';
import React from 'react';
import useDeviceListSearch from '@/hooks/useDeviceListSearch';
import { useBulkOperations } from '@/context/BulkOperationsContext';
import { useSelectedRows } from '../../../Table/DataTable/SelectedRowsProvider';

export interface DeviceActionType {
  actionType: 'editStatus' | 'editProfile' | 'assignSite';
}

interface DeviceActionsProps {
  actionType: DeviceActionType['actionType'];
  selectedData: DeviceTableData[];
  heading: string;
  modalOpen: boolean;
  setModalOpen: (data: boolean) => void;
  tableType?: TableType;
  setEditAction?: (data: any) => void;
  refreshCallback?: () => void;
}

interface ActionsProps {
  site?: any;
  push?: any;
  profiles?: any[];
  assignedSiteId?: number[];
  assignedProfileId?: string;
  assignedProfileName?: string;
}

const EditStatus = ({ currentStatus }: any) => {
  const statusValues = [
    { label: 'Activated', id: UsimStatus.ACTIVATED },
    { label: 'Deactivated', id: UsimStatus.DEACTIVATED }
  ];
  const currentStatusId = currentStatus ? statusValues.find(item => item.id === currentStatus)?.id : null;
  return (
    <ModalContentWrapper>
      <Field
        name='status'
        validate={required}
        render={({ input, meta }) => (
          <>
            <RadioGroup
              items={statusValues}
              initialCheckedId={currentStatusId}
              legend='Status'
              value={input.id}
              onChange={(newValue: string) => input.onChange(newValue)}
            />
            {meta.modified && <Typography variant={{ colour: 'danger' }}>{meta.error}</Typography>}
          </>
        )}
      />
    </ModalContentWrapper>
  );
};

const EditProfile = ({ profiles, assignedProfileId }: ActionsProps) => {
  return (
    <ModalContentWrapper>
      <Field
        name='profile'
        validate={required}
        render={({ input, meta }) => (
          <>
            <RadioGroup
              items={profiles}
              legend='Profile'
              value={input.value}
              initialCheckedId={'' + assignedProfileId}
              onChange={(newValue: string) => {
                input.onChange(JSON.parse(newValue));
              }}
              {...(meta.modified &&
                meta.error && {
                  validation: 'error'
                })}
            />
            {meta.modified && <Typography variant={{ colour: 'danger' }}>{meta.error}</Typography>}
          </>
        )}
      />
    </ModalContentWrapper>
  );
};

const AssignSite = ({ push, assignedSiteId, assignedProfileName }: ActionsProps) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { id: customerId } = router.query as unknown as queryParamsType;
  const customerSites = useSelectedCustomer().sites;
  const appSettings = useAppSettings()

  useMemo(() => {
    customerSites.map((item: any) => push(`assignSite`, item));
  }, [customerSites]);
  useEffect(() => {
    dispatch(setAppSettings({ isBeingSubmitted: true} as AppSettings))
    customerSites.forEach((site: any, index: any) => {
      NetworkConfigurationService.listUsimProfilesByCustomerSiteId(customerId, site.id, false)
        .then((resp) => {
          const profileOptions = resp.content.map((profile: any) => ({
            name: profile.name,
            id: profile.id,
            label: profile.name
          }));
          push(`assignSite[${index}].profiles`, profileOptions);
        })
        .catch((error) => {
          handleApiError(error, dispatch);
        });
    });
  }, [customerSites]);
  return (
    <ModalContentWrapper>
      <StackWrap direction='column' space={4}>
        {appSettings.isBeingSubmitted
         ? <Skeleton characters={6} sizeIndex={3} lines={3} />
         : <FlexGrid>
          <FlexGrid.Row>
            <FlexGrid.Col xs={6}>
              <Typography variant={{ bold: true }}>Site</Typography>
            </FlexGrid.Col>
            <FlexGrid.Col xs={6}>
              <Typography variant={{ bold: true }}>Profile</Typography>
            </FlexGrid.Col>
          </FlexGrid.Row>
          <Spacer space={2} />
          <FieldArray name='assignSite'>
            {({ fields }) =>
              fields.map((name, index) => (
                <React.Fragment key={index}>
                  <Spacer space={2} />
                  <FlexGrid.Row>
                    <FlexGrid.Col xs={6}>
                      <Field
                        name={`${name}.siteChecked`}
                        type='checkbox'
                        render={({ input }) => (
                          <Checkbox
                            key={`${assignedSiteId}-${fields.value[index].id}`}
                            label={fields.value[index].siteName}
                            defaultChecked={assignedSiteId.includes(fields.value[index].id)}
                            inactive={assignedSiteId.includes(fields.value[index].id)}
                            checked={input.value}
                            onChange={(newValue: string) => input.onChange(newValue)}
                          />
                        )}
                      />
                    </FlexGrid.Col>
                    <FlexGrid.Col xs={6}>
                      <FormSpy subscription={{ values: true }}>
                        {({ values, form }) => {
                          return (
                            <Field
                              name={`${name}.profile`}
                              render={({ input, meta }) => (
                                <>
                                  <Select
                                    testID={'profile-select'}
                                    placeholder={assignedSiteId !== fields.value[index].id ? '' : assignedProfileName}
                                    value={input.value && values.assignSite[index].siteChecked ? JSON.stringify(input.value) : ''}
                                    inactive={!values.assignSite[index].siteChecked}
                                    onChange={(newValue: string) => {
                                      input.onChange(JSON.parse(newValue));
                                    }}
                                    {...(meta.modified &&
                                      meta.error && {
                                        validation: 'error'
                                      })}
                                  >
                                    {fields.value[index]?.profiles?.map((profileList: any) =>
                                      profileList.map((profile: any, index: number) => (
                                        <Select.Item key={index} value={JSON.stringify({ id: profile.id, name: profile.name })}>
                                          {profile.name}
                                        </Select.Item>
                                      ))
                                    )}
                                  </Select>
                                  {meta.modified && <Typography variant={{ colour: 'danger' }}>{meta.error}</Typography>}
                                </>
                              )}
                            />
                          );
                        }}
                      </FormSpy>
                    </FlexGrid.Col>
                  </FlexGrid.Row>
                </React.Fragment>
              ))
            }
          </FieldArray>
        </FlexGrid>}
      </StackWrap>
    </ModalContentWrapper>
  );
};

const DeviceActions = ({ actionType, heading, selectedData, modalOpen, setModalOpen, tableType, setEditAction, refreshCallback }: DeviceActionsProps) => {
  const [profiles, setProfiles] = useState([]);
  const router = useRouter();
  const dispatch = useDispatch();
  const appSettings = useAppSettings();
  const { currentPage } = useTableState(TableType.DEVICE_LIST);
  const { clearSelectedRows } = useSelectedRows();
  const deviceList = (currentPage as DeviceSearchResponse).results;
  const [sameValue, setSameValue] = useState(null);
  const [currentStatus, setCurrentStatus] = useState<string>(null);
  const [currentProfile, setCurrentProfile] = useState(null);
  const [assignedSiteIds, setAssignedSiteIds] = useState<number[]>([]);
  const { id: customerId } = router.query as unknown as queryParamsType;

  const { deviceCount, devicePage } = useDeviceListSearch(modalOpen, +customerId);

  const currentSiteId = selectedData[0]?.siteId;

  const { addBulkOperation, removeBulkOperation } = useBulkOperations();

  const onSubmit = (form: any) => {
    setModalOpen(false);
    let bulkOperationId: string;

    if (actionType === 'editStatus') {
      bulkOperationId = addBulkOperation('Updating SIM Status', TableType.DEVICE_LIST);
      clearSelectedRows();
      setSameValue(form.status === currentStatus);
      const data: BulkSimStatusUpdateRequest[] = selectedData.map((dataItem) => ({
        simId: Number(dataItem.usimId),
        siteId: Number(dataItem.siteId)
      }));

      NetworkConfigurationService.bulkUpdateSimStatus(customerId, form.status, data)
        .then(async (res: BulkSimStatusUpdateResponse) => {
          dispatch(setNotification({
            message: `SuccessFull Requests: ${res.successfullRequest}, Failed Requests: ${res.failedRequest}, ${res.failedRequest !== 0 ? `Error Message: ${res?.failedSims?.[0].errorMessage}` : ''}`,
            type: res.failedRequest !== 0 ? 'error' : 'success'
          }));
        }).catch((err: any) => {
          handleApiError(err, dispatch);
        }).finally(() => {
          dispatch(setAppSettings({ isLoading: false } as AppSettings));
          dispatch(setCurrentPageable({ page: 0 }));
          dispatch(setAdvancedSearchParams(resetAdvancedSearchParams()));
          removeBulkOperation(bulkOperationId);
          if (refreshCallback) {
            refreshCallback()
          }
        });
    } else if (actionType === 'editProfile') {
      bulkOperationId = addBulkOperation('Updating SIM Profile', TableType.DEVICE_LIST);
      clearSelectedRows();
      const data: BulkSimProfileUpdateRequest[] = selectedData.map((dataItem) => ({
        simId: Number(dataItem.usimId),
        profileId: form.profile
      }));
      NetworkConfigurationService.bulkUpdateSimProfile(customerId, currentSiteId, data)
        .then((res: BulkSimProfileUpdateResponse) => {
          dispatch(setNotification({
            message: `SuccessFull Requests: ${res.successfullRequest}, Failed Requests: ${res.failedRequest}, ${res.failedRequest !== 0 ? `Error Message: ${res?.failedSims?.[0].errorMessage}` : ''}`,
            type: res.failedRequest !== 0 ? 'error' : 'success'
          }));
        }).catch((err: any) => {
          handleApiError(err, dispatch);
        }).finally(() => {
          dispatch(setAppSettings({ isLoading: false } as AppSettings));
          dispatch(setCurrentPageable({ page: 0 }));
          dispatch(setAdvancedSearchParams(resetAdvancedSearchParams()));
          removeBulkOperation(bulkOperationId);
          if (refreshCallback) {
            refreshCallback()
          }
        });
    } else if (actionType === 'assignSite') {
      bulkOperationId = addBulkOperation('Assigning Site', TableType.DEVICE_LIST);
      clearSelectedRows();
      const bulkSiteProvisionRequest: BulkSimProvisionRequest[] = selectedData.flatMap((dataItem) => (
        form?.assignSite
          .filter((newSite: any) => newSite.siteChecked)
          .map((newSite: any) => ({
            simId: Number(dataItem.usimId),
            siteId: Number(newSite?.id),
            profileId: newSite?.profile?.id
          }))
      ));
      NetworkConfigurationService.bulkProvisionSite(customerId, bulkSiteProvisionRequest)
        .then((res: BulkSimProvisionResponse) => {
          setEditAction((prev: any) => ({ ...prev, selectedData: undefined }));
          dispatch(setNotification({
            message: `SuccessFull Requests: ${res.successfullRequest}, Failed Requests: ${res.failedRequest}, ${res.failedRequest !== 0 ? `Error Message: ${JSON.stringify(res?.failedSims?.[0].errorMessage)}` : ''}`,
            type: res.failedRequest !== 0 ? 'error' : 'success'
          }));
        }).catch((err: any) => {
          handleApiError(err, dispatch);
        }).finally(() => {
          dispatch(setAppSettings({ isLoading: false } as AppSettings));
          dispatch(setCurrentPageable({ page: 0 }));
          dispatch(setAdvancedSearchParams(resetAdvancedSearchParams()));
          removeBulkOperation(bulkOperationId);
          if (refreshCallback) {
            refreshCallback()
          }
        });
    }
    dispatch(setAppSettings({ advancedSearchPanelState: false } as AppSettings));
    dispatch(setGeneralSearchQuery({ searchQuery: '' }));
    dispatch(setAdvancedSearchParams(resetAdvancedSearchParams()));
  };

  // find the assigned site ids
  useEffect(() => {
    if(actionType === 'assignSite') {
      if (!selectedData || selectedData.length === 0) {
        return;
      }
      const siteIds = new Set<number>();
      selectedData.forEach((item) => {
        siteIds.add(item.siteId);
      });
      setAssignedSiteIds(Array.from(siteIds));
    }
  }, [selectedData]);

  useMemo(() => {
    if (actionType === 'editProfile') {
      NetworkConfigurationService.listUsimProfilesByCustomerSiteId(customerId, currentSiteId, false)
        .then((resp) => {
          const profileOptions = resp.content.map((profile: any) => ({
            id: '' + profile.id,
            label: profile.name
          }));
          setProfiles(profileOptions);
        }).catch((error) => {
          handleApiError(error, dispatch);
        });
    }
  }, [actionType]);

  useMemo(() => {
    setCurrentStatus(selectedData[0]?.status);
    if (tableType === TableType.DEVICE_LIST || tableType === TableType.DEVICE_DETAILS) {
      setCurrentProfile(selectedData[0]?.usimProfileId);
    }
  }, [selectedData, tableType]);

  const disableSubmit = (form: any, modified: any) => {
    const isPristine = !Object.values(modified).some(Boolean);
  
    if (actionType === 'editStatus') {
      return isPristine || !form.status || form.status === currentStatus;
    } else if (actionType === 'editProfile') {
      return isPristine || !form.profile || form.profile === currentProfile;
    } else if (actionType === 'assignSite') {
      return isPristine || form.assignSite.some((site: any) => site.siteChecked && !site.profile);
    }
    return true; // Default case disable if actionType is not recognized
  };

  return (
    <Modal
      heading={heading}
      isOpen={modalOpen}
      variant={{ width: 'xl' }}
      onClose={() => {
        setModalOpen(false);
      }}
    >
      <Loader>
        <StackView space={8}>
          <Form
            onSubmit={onSubmit}
            initialValues={{}}
            mutators={{
              ...arrayMutators
            }}
            render={({
              handleSubmit,
              form: {
                mutators: { push },
                getState
              }
            }) => (
              <form onSubmit={handleSubmit}>
                <fieldset>
                  {actionType === 'editStatus' && <EditStatus currentStatus={currentStatus} />}
                  {actionType === 'editProfile' && <EditProfile profiles={profiles} assignedProfileId={currentProfile} />}
                  {actionType === 'assignSite' && <AssignSite push={push} assignedSiteId={assignedSiteIds} assignedProfileName={selectedData?.[0]?.profileName} />}
                </fieldset>
                <StackView direction='row' space={2} tokens={{ alignItems: 'center' }}>
                  <Button
                    type='submit'
                    disabled={getState().hasValidationErrors || disableSubmit(getState().values, getState().modified)}
                    onClick={handleSubmit}
                    variant={{ priority: 'high' }}
                  >
                    Submit
                  </Button>
                  <TextIconButton
                    content='Cancel'
                    loaderRequired={false}
                    onClick={() => {
                      setModalOpen(false);
                    }}
                    theme={{ button: { color: 'secondary' } }}
                  />
                </StackView>
              </form>
            )}
          />
        </StackView>
      </Loader>
    </Modal>
  );
};

export default DeviceActions;
