import { FlexGrid, Notification, Spacer, Spinner, StackView, StackWrap, Typography } from '@telus-uds/components-web';
import { useBulkOperations } from '@/context/BulkOperationsContext';
import { TableType } from '@/types/Table';

interface BulkOperationProps {
  location: TableType;
  locationIndex?: number;
}

const BulkOperation: React.FC<BulkOperationProps> = ({ location, locationIndex }) => {
  const { bulkOperations } = useBulkOperations();

  const filteredOperations = bulkOperations.filter(operation => 
    operation.location === location && 
    (locationIndex === undefined || operation.locationIndex === locationIndex)
  );

  if (filteredOperations.length === 0) {
    return null;
  }

  return (
    <>
      <Spacer space={3} />
      <StackView space={2}>
        {filteredOperations.map((operation) => (
          <Notification key={operation.bulkOperationId}>
            <StackWrap direction='row' space={4}>
              <Typography variant={{ size: 'medium' }}>{operation.message}</Typography>
              <Spinner variant={{ size: 'small' }} show={true} label='' />
            </StackWrap>
          </Notification>
        ))}
      </StackView>
      <Spacer space={5} />
    </>
  );
};

export default BulkOperation;
