export function formatTimeInterval(totalSeconds: number): string {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);

    let formattedInterval = '';
    if (hours > 0) {
        formattedInterval += `${hours} hr${hours > 1 ? 's' : ''}`;
    }
    if (minutes > 0) {
        if (formattedInterval.length > 0) {
            formattedInterval += ' ';
        }
        formattedInterval += `${minutes} min${minutes !== 1 ? 's' : ''}`;
    }

    return formattedInterval
}