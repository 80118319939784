import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { ApiError, DeviceSearchResponse, NetworkConfigurationService, UsimStatus } from "../generated";
import {
  AppSettings,
  DeviceSearchFields,
  setAppSettings,
  setCurrentPageable,
  setTableState,
  useAdvancedSearchPanelState,
  useAdvancedSearchParams,
  useCurrentPageable,
  useGeneralSearchQuery,
  useTableState,
} from "@/store/customerManagement"; // Assuming you have a similar action
import { handleApiError } from "@/utils/error-handler";
import { TableType } from "@/types/Table";

interface DeviceSearchParams {
  customerId: number,
  page?: any,
  size?: any,
  sort?: Array<any>,
  name?: string,
  imsi?: string,
  msisdn?: string,
  profile?: string,
  dateActivated?: string,
  createdAt?: string,
  siteIds?: Array<number>,
  statuses?: Array<UsimStatus>,
  searchTerm?: string,
}

const useDeviceListSearch = (
  modalOpen: boolean,
  customerId: number,
) => {
  const dispatch = useDispatch();
  const { searchQuery } = useGeneralSearchQuery();
  const {page: pageNumber} = useCurrentPageable();
  const [lastSimpleSearch, setLastSimpleSearch] = useState(searchQuery);
  const searchParams = useAdvancedSearchParams() as DeviceSearchFields;
  const advancedSearchOpen = useAdvancedSearchPanelState();
  const lastSearchParams = useRef(searchParams);
  const [deviceCount, setDeviceCount] = useState<DeviceSearchResponse>(null);
  // seting page data for export
  const { currentPage, pageSize, sortKeys } = useTableState(TableType.DEVICE_LIST);
  const devicePage = currentPage as DeviceSearchResponse;

  // const page = useDeviceListPage();

  // console.log("user Search Invoked");
  // Check if sort key is defined and set defaultSort accordingly
  
  // const defaultSort = sortKeys && sortKeys.length > 0 && sortKeys[0] === undefined; 

  const queryParams = useMemo(() => {
    if (!customerId) return;

    // Check if sort key is valid and set defaultSort accordingly
    const validSortKeys = sortKeys && sortKeys.length > 0 && sortKeys[0].split(',')[0] !== '';
    const sort = validSortKeys ? sortKeys : undefined;
    // console.log("sort", sort);

    const baseParams: DeviceSearchParams = {
      customerId,
      page: pageNumber,
      size: pageSize,
      sort: sort,
      searchTerm: searchQuery.trim(),
    };

    const params: any = { ...baseParams }; // Use 'any' temporarily to avoid TypeScript errors.

    // Remove empty fields to avoid sending them in the API call
    Object.keys(params).forEach((key) => {
      if (
        params[key] === "" ||
        params[key] === undefined ||
        (Array.isArray(params[key]) && params[key].length === 0)
      ) {
        delete params[key];
      }
    });

    if (advancedSearchOpen) {
      // Prepare and clean advanced parameters
      const advancedParams: { [key: string]: any } = {
        name: searchParams.name?.trim(),
        imsi: searchParams.imsi?.trim(),
        msisdn: searchParams.msisdn?.trim(),
        profile: searchParams.profileName?.trim(),
        dateActivated: searchParams.dateActivated,
        createdAt: searchParams.createdAt,
        siteIds:
          searchParams.siteName?.length > 0 ? searchParams.siteName : undefined,
        statuses:
          searchParams.status?.length > 0 ? searchParams.status : undefined,
      };
      // Append advanced parameters if they are not empty or undefined
      Object.entries(advancedParams).forEach(([key, value]) => {
        if (
          value !== undefined &&
          value !== "" &&
          !(Array.isArray(value) && value.length === 0)
        ) {
          params[key] = value;
        }
      });
    }
    return params as DeviceSearchParams; // Cast back to the proper type
  }, [customerId, pageNumber, pageSize, sortKeys, searchQuery, advancedSearchOpen, searchParams]);
// }, []);

  const fetchSimAndCount = useCallback(async (params: DeviceSearchParams) => {
    try {
      // dispatch(setAppSettings({ isBeingSubmitted: true } as AppSettings))
      const data =
        await NetworkConfigurationService.listAllSimsByCustomer(
          params.customerId,
          params.page,
          params.size,
          params.sort,
          params.name,
          params.imsi,
          params.msisdn,
          params.profile,
          params.dateActivated,
          params.createdAt,
          params.siteIds,
          params.statuses,
          params.searchTerm
        );

      // console.log("Data received:", data);
      setDeviceCount(data);

      dispatch(setTableState({ id: TableType.DEVICE_LIST, state: { currentPage: data } }))
      // dispatch(setDeviceListPage(data));
    } catch (error) {
      if (error instanceof ApiError) {
        // Error is of type ApiError, handle it accordingly
        const errorMessages = {
          500: `SIM Data is not available at the moment. Please try again later.`,
        }
        handleApiError(error, dispatch, errorMessages);
        dispatch(setAppSettings({ isLoading: false } as AppSettings))
      } else {
        // Error is not an ApiError, maybe log it or handle it differently
        console.log('An unexpected error occurred', error);
      }
    }

  }, [dispatch]);

  useEffect(() => {
    if (!customerId) return;

    if (searchQuery !== lastSimpleSearch) {
      dispatch(setCurrentPageable({ page: 0, size: pageSize })) // Reset page number on new search
      setLastSimpleSearch(searchQuery);
    }
    if (advancedSearchOpen && lastSearchParams.current !== searchParams) {
      dispatch(setCurrentPageable({ page: 0, size: pageSize }))
      lastSearchParams.current = searchParams;
    }
    fetchSimAndCount(queryParams);
    // MODAL OPEN DEPENDENCY IS REQIURED TO UPDATE THE TABLE DATA AFTER PERFORMING DEVICE ACTIONS
  }, [searchQuery, customerId, pageNumber, lastSimpleSearch, advancedSearchOpen, searchParams, fetchSimAndCount, modalOpen, queryParams, dispatch, pageSize]);



  return { deviceCount, devicePage };
};

export default useDeviceListSearch;
