import { AppSettings, setAppSettings, useAdvancedSearchPanelState } from '@/store/customerManagement'
import { TableType } from '@/types/Table'
import { FlexGrid, Spacer, StackView } from '@telus-uds/components-web'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import AdvancedSearch from '../../AdvancedSearch'
import { CardWrapper } from '../../Dashboard/CustomerDashBoard/Devices/DeviceDetails'
import DataTableSearch from './DataTableSearch'

interface TableHeaderProps {
  isSearchable?: boolean
  tableButtons?: JSX.Element
  tableType?: TableType
}
const TableHeader = ({tableButtons, tableType, isSearchable}: TableHeaderProps) => {
  const advancedSearchOpen = useAdvancedSearchPanelState(); // Get the current state of the advanced search panel
  const dispatch = useDispatch();

  useEffect(() => {
    // Reset the advanced search panel state when the component unmounts
    return () => {
      dispatch(setAppSettings({ advancedSearchPanelState: false } as AppSettings));
    };
  }, []);
  {/* Search and Table Buttons */}
  return (
    <>
      <FlexGrid outsideGutter={false} limitWidth={false}>
        <FlexGrid.Row distribute="between">
          <FlexGrid.Col xs={12} md={5}>
            {isSearchable ? <StackView tokens={{
              alignItems: 'baseline',
              justifyContent: 'start',
              flexGrow: 1
            }} divider>
              <DataTableSearch/>
            </StackView> : <></>}
          </FlexGrid.Col>
          <FlexGrid.Col xs={12} md={3}>
            {tableButtons || <></>}
          </FlexGrid.Col>
        </FlexGrid.Row>
        <CardWrapper>
          <FlexGrid.Row>
            <FlexGrid.Col xs={12}>
              {
                advancedSearchOpen ? <AdvancedSearch tableType={tableType}/> : <></>
              }
            </FlexGrid.Col>
          </FlexGrid.Row>
        </CardWrapper>
      </FlexGrid>
      <Spacer space={2}/>
    </>
  )
}

export default TableHeader
