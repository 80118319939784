import { Page } from '@/api/index'
import { Table } from '@/components/Table'
import { ColumnDefinitionType } from '@/components/Table/DataTableTypes'
import Loader from '@/components/ToolBar/Loader'
import { Status } from '@/components/ToolBar/Status'
import { useCustomerSearch } from '@/hooks/useCustomerSearch'
import { resetCustomerDataStates, useTableState } from '@/store/customerManagement'
import { NoWrap, StyledLinkWrapper } from '@/styles/theme/styledComponents'
import { TableType } from '@/types/Table'
import { queryParamsType } from '@/types/global'
import { Spacer, StackView, Typography } from '@telus-uds/components-web'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import CustomerExpandedView from './CustomerExpandedView'
import CustomerTableButtons from './CustomerTableButtons'
import { Direction } from '@/hooks/useAppSorting'

export interface PrimaryContact {
  contactName: string
  phone: number
  emailId: string
  description: string
}

export interface CustomerBillingDetails {
  address: string
  country: string
  city: string
  postalCode: string
  province: string
  billable?: boolean
}
export interface CustomerTableData {
  id: number
  customerName: string;
  ban: number;
  customerStatus: string;
  primaryContact: PrimaryContact
  billingDetails: CustomerBillingDetails
  shippingDetails: CustomerBillingDetails
}

const TelusHomePage = () => {
  const router = useRouter()
  const { currentPage } = useTableState(TableType.CUSTOMER_LIST);
  const data = (currentPage as Page).content
  const dispatch = useDispatch()

  // clear the redux for devices, users, ... etc to avoid data or settings from previous customer displaying
  useEffect(() => {
    dispatch(resetCustomerDataStates()); // Reset the Redux state on component mount
  }, [dispatch]);

  // TODO: Handle API Error

  const tableData: CustomerTableData[] = data?.map((customer) => {
    return {
      id: customer.id,
      customerName: customer.customerName,
      ban: customer.ban,
      customerStatus: customer.customerStatus,
      primaryContact: customer.primaryContact,
      billingDetails: customer.billingInformation,
      shippingDetails: customer.shippingInformation
    }
  });
  const columns: ColumnDefinitionType<CustomerTableData, keyof CustomerTableData>[] = [
    {
      key: 'customerName',
      header: 'Account Name',
      renderer: (data, rowData) => <NoWrap>
        <StyledLinkWrapper>
            <Link href={`/customer/${rowData.id}`}>{data as String}</Link>
          </StyledLinkWrapper>
      </NoWrap>,
      columnWidth: '20%'
    },
    { key: 'id', header: 'Customer Id', columnWidth: '25%'},
    { key: 'ban', header: 'BAN', columnWidth: '20%'},
    {
      key: 'customerStatus',
      header: 'Status',
      renderer: (data, rowData) => <Status message={rowData.customerStatus} active={(data as String)?.toUpperCase() === "ACTIVE"}/> },
  ]
  useCustomerSearch();
  const expandableViewFn = (rowData: CustomerTableData) => {
    return (
      <CustomerExpandedView data={rowData} />
    )
  }
  const tableButtons = (
    <CustomerTableButtons />
  )
  return (
    <StackView>
      <Typography variant={{ size: 'h2'}} >Customer Accounts</Typography>
      <Spacer space={5} />
      <Loader>
        <Table
          data={tableData}
          columns={columns}
          isExpandable={true}
          isSortable={true}
          defaultSortDirection={Direction.ASC}
          defaultSortKey="id"
          expandableFn={expandableViewFn}
          tableType={TableType.CUSTOMER_LIST}
          tableButtons={tableButtons}
          page={currentPage as Page}
        />
      </Loader>
    </StackView>
  )
}

export default TelusHomePage
