export const LocalTime = (date: string) => {
    const utcDate = new Date(date);
    // Get the user's local time zone
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // Format the date in the user's local time
    const localDateString = utcDate.toLocaleString("en-US", {
      timeZone: userTimeZone,
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true // Use 12-hour format
    });
    // Remove the comma
    return localDateString.replace(/,\s+/, ' ');
  };