import { CheckboxGroup, FlexGrid, Spacer, StackView, TextInput, Typography } from '@telus-uds/components-web';
import React, { useContext, useState } from 'react'
import { Field } from 'react-final-form';
import { SearchProps } from '.';
import FormContext from './FormContext';

const CustomerSearch = ({ handleChange }: SearchProps) => {
  const { resetKey } = useContext(FormContext);

  const customerStatus = [
    { id: "active", label: "Active" },
    { id: "inactive", label: "Inactive" }
  ]
  return (
    <FlexGrid outsideGutter={false} limitWidth={false}>
      <FlexGrid.Row verticalAlign="middle">
        <FlexGrid.Col xs={12} md={6}>
          <Field
            name="customerName"
            render={({ input, meta }) => (
              <TextInput
                label="Customer Name"
                value={input.value}
                onChange={(newValue: string) => {
                  handleChange(input)(newValue);
                }}
              />
            )}
          />
        </FlexGrid.Col>
        <FlexGrid.Col xs={12} md={6}>
          <Field
            name="id"
            render={({ input, meta }) => (
              <TextInput
                label="Customer Id"
                value={input.value}
                onChange={(newValue: string) => {
                  handleChange(input)(newValue);
                }}
              />
            )}
          />
        </FlexGrid.Col>
      </FlexGrid.Row>
      <Spacer space={4} />
      <FlexGrid.Row verticalAlign="top">
        <FlexGrid.Col xs={12} md={6}>
          <Field
            name="ban"
            render={({ input, meta }) => (
              <TextInput
                label="BAN"
                placeholder="Business Account Number"
                value={input.value}
                onChange={(newValue: string) => {
                  handleChange(input)(newValue);
                }}
                {...(meta.modified &&
                  meta.error && {
                  validation: "error"
                })}
              />
            )}
          />
        </FlexGrid.Col>
        <FlexGrid.Col xs={12} md={4}>
          <Field
            name="customerStatus"
            render={({ input }) => (
              <StackView space={2}>
                <Typography variant={{ bold: true }}>Customer Status</Typography>
                <CheckboxGroup
                  key={`status-${resetKey}`}
                  initialCheckedIds={[]}
                  checkedId={input.value || []}
                  onChange={(newCheckedIds: string[]) => {
                    const selectedLabels = newCheckedIds.map(id => 
                      customerStatus.find(status => status.id === id)?.label
                    );
                    input.onChange(selectedLabels); // React Final Form's input.onChange will handle state update
                    handleChange(input)(selectedLabels.join(","));
                  }}
                  items={customerStatus}
                />
              </StackView>
            )}
          />
        </FlexGrid.Col>
      </FlexGrid.Row>
    </FlexGrid>
  )
}

export default CustomerSearch
