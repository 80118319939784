import { NetworkConfigurationService, Role, UsimDataTableViewResponse } from '@/api/index'
import TextIconButton from '@/components/ToolBar/TextIconButton'
import { AppSettings, setAppSettings, useCurrentUserRole, useSelectedCustomer } from '@/store/customerManagement'
import { DropDown } from '@/styles/theme/styledComponents'
import { buttonDropDownTheme, disabledActionButtonTheme, disabledButtonTheme, dropDownItemTheme, dropDownTheme, getStyling, tableTopButtonTheme, tableTopIconTheme } from '@/styles/theme/themes'
import { isAnyViewerRole } from '@/types/RoleMapping'
import { Button, Card, Icon, Spacer, StackView } from '@telus-uds/components-web'
import { CaretDown, CaretUp, DownloadPdf } from '@telus-uds/palette-allium/build/web/icons'
import { useEffect, useState } from 'react'
import DeviceActions, { DeviceActionType } from '../DeviceActions'
import TableSizeSelect from '@/components/Table/TableHeader/TableSizeSelect'
import { TableType } from '@/types/Table'
import { DeviceTableData, EditProps } from '..'
import { handleApiError } from '@/utils/error-handler'
import { queryParamsType } from '@/types/global'
import { useRouter } from 'next/router'
import { useDispatch } from 'react-redux'
import { useBulkOperations } from '@/context/BulkOperationsContext'

interface TableButtonsProps {
  editAction: any
  setEditAction?: (data: EditProps) => void
  setModalOpen: (data: any) => void
  modalOpen: boolean
  handleExport?: () => void
  tableType: TableType
  handleToggleModal?: () => void
  refreshCallback?: () => void
}

const TableButtons = (props: TableButtonsProps) => {
  const router = useRouter();
  const dispatch = useDispatch()
  const [actionType, setActionType] = useState<DeviceActionType['actionType']>(null)
  const currentUserRole: Role = useCurrentUserRole();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { id: customerId } = router.query as unknown as queryParamsType;
  const [selectedData, setSelectedData] = useState<any>([]);

  const { hasOperation } = useBulkOperations();

  const handleDeviceActionClick = (actionType: DeviceActionType['actionType']) => {
    // setEditModalOpen(!editModalOpen)
    setActionType(actionType)
    setDropdownOpen(!dropdownOpen)
    props.setModalOpen(!props.modalOpen)
  }


  // for assigning a site we have to check the entire db to find all the sites to which this imsi is associated
  // otherwise we will get the error that the sim is already assigned to a site (if the sim is assigned to that particular site in the db but not in the current view)
  const fetchSimAndCountByImsi =  async(imsiList: Set<string>): Promise<Set<UsimDataTableViewResponse>> => {
    let resultSet = new Set<UsimDataTableViewResponse>();
    
    try {
      const promises = Array.from(imsiList).map((imsi) => {
        return NetworkConfigurationService.listAllSimsByCustomer(
          customerId,
          0,
          0,
          undefined,
          undefined,
          imsi,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined
        ).then((res) => {
          res?.results?.content?.forEach((item) => {
            resultSet.add(item);
          });
        }).catch((error) => {
          handleApiError(error);
        })
      }) 
      await Promise.all(promises).then (() => {
        dispatch(setAppSettings({ isBeingSubmitted: false} as AppSettings))
      });
      return resultSet;
    }catch (error) {
      dispatch(setAppSettings({ isBeingSubmitted: false} as AppSettings))
      console.error("Error fetching SIMs by IMSI:", error);
    }
  };

  useEffect(() => {
    if(props.modalOpen && actionType === 'assignSite') {
      const fetchData = async () => {
        if (!props.editAction.selectedData || props.editAction.selectedData.length === 0) {
          return;
        }
        const selectedImsi = new Set<string>(props.editAction.selectedData.map((item: { imsi: DeviceTableData }) => item?.imsi));
        const data = await fetchSimAndCountByImsi(selectedImsi);
        setSelectedData(Array.from(data))
      };
      fetchData()
    }
  }, [actionType, props.editAction.selectedData]);

  useEffect(() => {
    if (dropdownOpen && props.editAction.selectedData.length > 0 && (TableType.DEVICE_DETAILS === props.tableType)) {
      //  disable edit profile if there is only one profile for the site
      const firstRow = props.editAction.selectedData[0];
      NetworkConfigurationService.listUsimProfilesByCustomerSiteId(customerId, firstRow?.siteId, false)
        .then((resp) => {
          if (resp.content.length === 1) {
            props.editAction.editProfile = false
          }
        }).catch((error) => {
          console.log("Could not fetch profiles", error);
        });
    }
  }, [dropdownOpen, props.editAction.selectedData])

  console.log("hasOperation:", hasOperation(TableType.DEVICE_LIST))
  console.log("editable:", props.editAction.editable)
  console.log("disabled:", !props.editAction.editable && !hasOperation(TableType.DEVICE_LIST))

  return (
    <div style={{ marginRight: '8px' }}>
    {props.modalOpen && <DeviceActions
        tableType={props.tableType}
        heading={actionType === 'editStatus' ? 'Edit Status' : (actionType === 'editProfile' ? 'Edit Profile' : 'Assign Site')}
        actionType={actionType}
        modalOpen={props.modalOpen}
        setModalOpen={props.setModalOpen}
        selectedData={actionType === 'assignSite' ? selectedData : props.editAction.selectedData}
        setEditAction={props.setEditAction}
        refreshCallback={props.refreshCallback}
      />}
      <StackView>
        <StackView space={4} divider tokens={{ justifyContent: 'flex-end' }} direction="row">
          <StackView>
            { !isAnyViewerRole(currentUserRole?.name) &&
                <Button
                  tokens={!props.editAction.editable ? getStyling(disabledButtonTheme) : getStyling(buttonDropDownTheme)}
                  onPress={() => setDropdownOpen(!dropdownOpen)}
                  disabled={!props.editAction.editable || hasOperation(TableType.DEVICE_LIST)}
                  icon={dropdownOpen ? CaretUp : CaretDown}
                  iconPosition="right"
                >
                  Actions
                </Button>
            }
            {dropdownOpen && props.editAction.editable &&
                <DropDown>
                  <Card tokens={getStyling(dropDownTheme)}>
                    <StackView space={2} divider>
                      <TextIconButton
                        loaderRequired={false}
                        content='Edit Status'
                        onClick={() => { handleDeviceActionClick('editStatus') }}
                        theme={dropDownItemTheme}
                        disabled={!props.editAction.editStatus}
                        disableTheme={disabledActionButtonTheme}
                      />
                      <TextIconButton
                        loaderRequired={false}
                        content='Edit Profile'
                        onClick={() => { handleDeviceActionClick('editProfile') }}
                        theme={dropDownItemTheme}
                        disabled={!props.editAction.editProfile}
                        disableTheme={disabledActionButtonTheme}
                      />
                      {props.tableType === TableType.DEVICE_LIST && <TextIconButton
                        loaderRequired={false}
                        content='Assign a new site'
                        onClick={() => handleDeviceActionClick('assignSite')}
                        theme={dropDownItemTheme}
                        disabled={!props.editAction.assignSite}
                        disableTheme={disabledActionButtonTheme}
                      />}
                    </StackView>
                  </Card>
                </DropDown>
            }
          </StackView>
          {props.tableType === TableType.DEVICE_LIST && <>
          <TextIconButton
            loaderRequired={false}
            content='Export'
            onClick={props.handleExport}
            theme={tableTopButtonTheme}
            iconTheme={tableTopIconTheme}
            icon={DownloadPdf}
          />
          <TableSizeSelect tableType={TableType.DEVICE_LIST}/>
          </>}
        </StackView>
      </StackView>
    </div>
  )
}

export default TableButtons
