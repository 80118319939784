import { useAppSettings } from "@/store/customerManagement"
import { ActivityIndicator } from "@telus-uds/components-web"
import { ReactNode } from "react";

interface LoaderProps {
  children: ReactNode,
  showLoader?: boolean
}

const Loader = (props: LoaderProps) => {
 const appSettings = useAppSettings()
  return (
    <>
      { appSettings.isLoading || props.showLoader
          ? <ActivityIndicator label={'Loading'} variant={{ size: 'large' }}/>
          : <>{props.children}</>
      }
    </>
  )
}

export default Loader
