import { FlexGrid, Select, Spacer, StackView, TextInput } from '@telus-uds/components-web';
import React, { useContext } from 'react';
import { Field } from 'react-final-form';
import { SearchProps } from '.';
import FormContext from './FormContext';
import { useTelusUserRoles } from '@/store/customerManagement';
import { UserStatus } from '@/api/index';

const TelusUserSearch = ({ handleChange }: SearchProps) => {
  const { resetKey } = useContext(FormContext);

  const roleValues = useTelusUserRoles();

  const userStatus = [
    { id: UserStatus.ACTIVE, label: "ACTIVE" },
    { id: UserStatus.INACTIVE, label: "INACTIVE" }
  ];

  const clearOption = { id: 'clear', label: '*Clear*' };

  return (
    <FlexGrid outsideGutter={false} limitWidth={false}>
      <FlexGrid.Row verticalAlign="middle">
        <FlexGrid.Col xs={12} md={6}>
          <Field
            name="name"
            render={({ input }) => (
              <TextInput
                label="Name"
                placeholder="Name"
                onChange={(str: string) => {
                  handleChange(input)(str);
                }}
                value={"" + input.value}
              />
            )}
          />
        </FlexGrid.Col>
        <FlexGrid.Col xs={12} md={6}>
          <Field
            name="username"
            render={({ input }) => (
              <TextInput
                label="Username"
                placeholder="Username"
                onChange={(str: string) => {
                  handleChange(input)(str);
                }}
                value={"" + input.value}
              />
            )}
          />
        </FlexGrid.Col>
      </FlexGrid.Row>
      <Spacer space={2} />
      <FlexGrid.Row verticalAlign="top">
        <FlexGrid.Col xs={12} md={6}>
          <Field
            name="role.label"
            render={({ input, meta }) => (
              <StackView space={2}>
                <Select
                  key={`role-${resetKey}`}
                  label="Role"
                  placeholder="Role"
                  value={input.value}
                  onChange={(newValue: string) => {
                    if (newValue === clearOption.label) {
                      input.onChange(null);
                      handleChange(input)("");
                    } else {
                      const selectedRole = roleValues.find(role => role.label === newValue);
                      input.onChange(selectedRole ? selectedRole.label : null);
                      handleChange(input)(newValue);
                    }
                  }}
                  {...(meta.error && (meta.modified || meta.submitFailed) && {
                    validation: "error"
                  })}
                >
                  {input.value && (
                    <Select.Item key={clearOption.id} value={clearOption.label} tokens={{ height: 4 }}>
                      {clearOption.label}
                    </Select.Item>
                  )}
                  {roleValues.map((role) => (
                    <Select.Item key={role.id} value={role.label} tokens={{ height: 4 }}>
                      {role.label}
                    </Select.Item>
                  ))}
                </Select>
              </StackView>
            )}
          />
        </FlexGrid.Col>
        <FlexGrid.Col xs={12} md={6}>
          <Field
            name="status"
            render={({ input, meta }) => (
              <StackView space={2}>
                <Select
                  key={`status-${resetKey}`}
                  label="Status"
                  placeholder="Status"
                  value={input.value}
                  onChange={(newValue: string) => {
                    if (newValue === clearOption.label) {
                      input.onChange(null);
                      handleChange(input)("");
                    } else {
                      const selectedStatus = userStatus.find(status => status.label === newValue);
                      input.onChange(selectedStatus ? selectedStatus.label : null);
                      handleChange(input)(newValue);
                    }
                  }}
                  {...(meta.error && (meta.modified || meta.submitFailed) && {
                    validation: "error"
                  })}
                >
                  {input.value && (
                    <Select.Item key={clearOption.id} value={clearOption.label} tokens={{ height: 4 }}>
                      {clearOption.label}
                    </Select.Item>
                  )}
                  {userStatus.map((status) => (
                    <Select.Item key={status.id} value={status.label} tokens={{ height: 4 }}>
                      {status.label}
                    </Select.Item>
                  ))}
                </Select>
              </StackView>
            )}
          />
        </FlexGrid.Col>
      </FlexGrid.Row>
    </FlexGrid>
  );
};

export default TelusUserSearch;
