import { setCurrentPageable, useCurrentPageable, useTableState } from '@/store/customerManagement';
import { TableType } from '@/types/Table';
import { Pagination } from '@telus-uds/components-web';
import { useCallback, useMemo, type MutableRefObject } from 'react';
import { useDispatch } from 'react-redux';

interface TablePaginationProps {
  scrollTo: MutableRefObject<HTMLTableElement | null>
  totalPages: number
  tableType: TableType
  tableIndex?: number
}

const TablePagination: React.FC<TablePaginationProps> = ({ totalPages, scrollTo, tableType}: TablePaginationProps) => {
  const pageArray = useMemo(() => Array.from(Array(totalPages), (_, index) => index), [totalPages]) // Page Array [1, 2, ...N]
  const PageButton = useMemo(() => (Pagination as any).PageButton, [])
  const dispatch = useDispatch();
  const { page: currentPageNumber } = useCurrentPageable();
  const { pageSize, currentPage } = useTableState(tableType);
  // const [currPageSize, setCurrentPageSize] = useState(useTableSizes()?.[tableType])

  const onChangePage = useCallback((nextPage: number) => {
    // console.log("Page change requested to:", nextPage); // This will confirm the method is being triggered
    scrollTo.current?.scrollIntoView({ behavior: 'smooth' });
    dispatch(setCurrentPageable({page: nextPage, size: pageSize}))
  }, [scrollTo]);

  return (
    <>
      {(totalPages > 1 && currentPageNumber !== undefined) &&
      <Pagination>
        {pageArray.map((index) => (
          <PageButton onPress={() => onChangePage(index)} isActive={currentPageNumber === index} key={`page-${index}`} />
        ))}
      </Pagination>
      }
    </>
  )
}

export default TablePagination
