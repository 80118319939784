import { Checkbox, Table } from "@telus-uds/components-web";
import React, { useState } from "react";
import { ColumnDefinitionType, ExpandedRows } from "../DataTableTypes";
import { useSelectedRows } from "./SelectedRowsProvider";
import TableExpandableButton from "./TableExpandableButton";
import { CompactTableCell } from "@/styles/theme/styledComponents";
import styled from "styled-components";

interface TableRowsProps<T, K extends keyof T>  {
  rowKey?: keyof T
  data: Array<T>
  columns: Array<ColumnDefinitionType<T, K>>
  isSelectable?: boolean
  isExpandable?: boolean
  expandableFn?: (rowData: T) => JSX.Element
  onRowSelectionChange?: (id: string) => void
  selectedRows?: Record<string, boolean>
}

const TableCell = styled.td<{width?: string}>`
    display: table-cell;
    text-align: left;
    padding: 16px 16px 16px 16px;
    background-color: rgba(0, 0, 0, 0);
    box-shadow: inset 0 1px 0 #e3e6e8;
    display: table-cell;
    unicode-bidi: isolate;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    color: rgb(0, 0, 0);
    text-transform: none;
    text-decoration-line: none;
    line-height: 24px;
    font-family: HelveticaNow400normal !important;
    min-width: ${props => props.width || 'auto'} !important;
`;
const TableRow = <T extends { id?: string }, K extends keyof T>({ data, rowKey, columns, isExpandable, expandableFn, isSelectable }: TableRowsProps<T, K>) => {
  const [expandedRows, setExpandedRows] = useState<ExpandedRows>({});
  const { selectedRows, handleRowSelectionChange } = useSelectedRows()
  const toggleRowExpansion = (rowId: string) => {
    setExpandedRows((prevExpandedRows: ExpandedRows) => ({
      ...prevExpandedRows,
      [rowId]: !prevExpandedRows[rowId]
    }))
  };
  const keyId = rowKey || 'id';

  return (
    <Table.Body>
      {data?.map((row, rowIndex) => (
        <React.Fragment key={rowIndex}>
          <Table.Row key={(row[keyId] as string) || rowIndex}>
            <>
              {/* Checkbox */}
              {isSelectable && 
                <CompactTableCell>
                    <Checkbox
                      checked={selectedRows[row[keyId]] || false}
                      onChange={() => {
                        const id = row[keyId] as string;
                        handleRowSelectionChange(id);
                      }}
                    />
                </CompactTableCell>
              }
              {/* Expandable Icon */}
              {isExpandable && <TableExpandableButton rowKey={ row[keyId] as string || rowIndex} expandedRows={expandedRows} setExpandedRows={setExpandedRows} handleExpansion={toggleRowExpansion}/>}
              {/* Row Contents */}
              {columns.map((column, columnIndex) => {
                const CustomTableCell = column.columnWidth ? TableCell : Table.Cell;
                return (
                <CustomTableCell width={column.columnWidth} key={columnIndex}>
                  {column.renderer ? (
                    <>{column.renderer(row[column.key], row)}</>
                  ) : (
                    row[column.key]?.toString()
                  )}
                </CustomTableCell>
              )})}
            </>
          </Table.Row>
          {isExpandable && expandedRows[row[keyId] as string || rowIndex] && (
            <Table.Row>
              <td colSpan={6}>
                {expandableFn(row)}
              </td>
            </Table.Row>
          )}
        </React.Fragment>
      ))}
    </Table.Body>
  );
};

export default TableRow;